import React from 'react';
import { Typography } from '@mui/material';
import { getBlackOrWhiteCalculated } from '../../../../components/General/Colors';
import { usePagesStore } from '../../../../stores/PagesStore';

const WidgetTitle = ({ widget }) => {
    const homePage = usePagesStore(state => state.homePage);
    if (widget.hideTitle) {
        return null;
    }

    return (
        <Typography
            variant="h6"
            fontSize={21}
            fontWeight="bold"
            fontFamily="Cabin"
            color={getBlackOrWhiteCalculated(homePage.homeBgColor)}
        >
            {widget.title}
        </Typography>
    );
};

export default WidgetTitle;
