import async from 'async';
import sortBy from 'lodash/sortBy';
import { getItem } from '../../../services/api/graphQlRepository';
import { mapStudioNav } from '../../../components/Navigation/utils/utils';

var getTabsElements = function (pageId, pages, next) {
    async.waterfall(
        [
            function (callback) {
                //Get the tab page
                if (pageId) {
                    getItem('pages', pageId, callback);
                } else {
                    callback('no pageId found');
                }
            },
            function (page, callback) {
                let data = page.params;
                if (page.navItems && page.navItems.length > 0) {
                    const ordered = sortBy(page.navItems, 'order');
                    const mappedItems = mapStudioNav(ordered, pages);
                    const items = mappedItems.map(item => {
                        return {
                            name: item.title,
                            screen: item.type,
                            objectId: item.objectId,
                            page: item.objectId || item.pageId,
                        };
                    });
                    data = {
                        items,
                        showPageNameInBanner: page.showPageNameInBanner,
                        bannerImage: page.pageBanner,
                        initialItem: 0,
                    };
                }
                if (page && data) {
                    callback(null, data);
                } else {
                    callback('no pageId found');
                }
            },
        ],
        function (err, result) {
            next(err, result);
        },
    );
};

export { getTabsElements };
