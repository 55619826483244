import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';

import Loader from '../../components/General/Loader';
import Search from '../../components/General/Search';
import * as palette from '../../components/General/Variables';

import SearchList from './components/list';
import Placeholder from './components/placeholder';
import { getSearchPage, searchData } from './services/search';
import AnalyticsService from '../../features/analytics/services';

const Header = styled.div`
    margin: 0px;
    height: 72px;
    padding: 16px !important;
    background-color: ${palette.COLOR_WHITE};
    width: 100%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05),
        0px 2px 4px rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 0;
    z-index: 1;

    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        padding-top: 10px;
    }
`;

const LoaderContainer = styled.div`
    padding-bottom: 8px;
`;

const Scrollable = styled.section.attrs({
    className: '',
})`
    height: 100%;
    overflow: auto;
`;

const GROUPING_RESULTS_LIMIT = 2;

const SearchPage = ({ pageId, updateTitle, setTitle }) => {
    const [searchState, setSearchState] = useState({
        loading: false,
        submitted: false,
        hasQResults: false,
        results: {},
        searchQuery: '',
    });
    const [pageParams, setPageParams] = useState(null);
    const [searchSections, setSearchSections] = useState(null);
    const [pageIntroText, setPageIntroText] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTitle?.();
        getSearchPage(pageId, (err, page) => {
            if (!err) {
                setPageParams(page);
                let sections = get(page, 'sections');
                if (!(sections && sections.length)) {
                    sections = get(page, 'params.sections');
                }
                setSearchSections(sections);
                let intro = get(page, 'subtitle');
                if (!intro) {
                    intro = get(page, 'params.introText');
                }
                setPageIntroText(intro);
                const pageTitle = page?.title || page.params.title;
                if (updateTitle) {
                    updateTitle(pageTitle);
                }
            } else {
                console.error(`Something wrong loading search page: ${err}`);
            }
            setLoading(false);
        });
    }, [pageId]);

    const onSearch = str => {
        if (str && str.length) {
            setSearchState({
                loading: true,
                submitted: true,
                hasQResults: false,
                results: {},
                searchQuery: str,
            });

            setTimeout(() => {
                AnalyticsService.addSample('searchQuery', str, str);
                getSearchData(str);
            }, 100);
        } else {
            setSearchState({
                loading: false,
                submitted: false,
                hasQResults: false,
                results: {},
                searchQuery: '',
            });
        }
    };

    const getSearchData = async queryString => {
        searchData(
            queryString,
            searchSections,
            pageParams.event,
            ({ hasResults, searchResults }) => {
                setSearchState({
                    ...searchState,
                    loading: false,
                    submitted: true,
                    hasQResults: hasResults,
                    results: searchResults,
                });
            },
        );
    };

    /**
     * @summary Renders content based on search result. Decides whether to render the list of
     * results or the empty view.
     */
    const renderContent = () => {
        if (searchState.loading) {
            return (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            );
        } else {
            return searchState.hasQResults ? renderSearchResults() : renderNoResults();
        }
    };

    const renderEmptySearch = () => <Placeholder type="emptySearch" introText={pageIntroText} />;
    const renderNoResults = () => <Placeholder type="noResults" introText={pageIntroText} />;

    const renderSearchResults = () => {
        return (
            <SearchList
                pageId={pageId}
                results={searchState.results}
                limit={GROUPING_RESULTS_LIMIT}
            />
        );
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Scrollable>
            <Header>
                <Search action={onSearch} isExpandableSearch={true} displayLeft={true} />
            </Header>
            {!searchState.submitted ? renderEmptySearch() : renderContent()}
        </Scrollable>
    );
};

export default SearchPage;
