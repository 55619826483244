import React from 'react';
import { convertNodeToElement } from 'react-html-parser';
import { getObjectClassWithId, getLocalAppState } from '../services/api/db';
import { executeQuery } from '../services/api/graphQlRepository';
import { makeNavigationData } from '../components/Navigation/NavigationLink';

const PATTERN = 'cc://';
const PATTERN_OBJECT = 'objectId=';
let historyCopy = null;

export const parseCCLink = url => {
    let idx = url.indexOf(PATTERN);
    if (idx === -1) {
        return null;
    }

    const parsedUrl = new URL(url);
    let main = parsedUrl.host;
    if (!main) {
        main = parsedUrl.pathname.replace('//', '');
    }
    const pageId = parsedUrl.searchParams.get('pageId');
    const objectId = parsedUrl.searchParams.get('objectId');

    if (objectId) {
        return { objectId, type: 'object' };
    } else {
        return { screen: main, pageId };
    }
};

const getLink = url => {
    let idx = url.indexOf(PATTERN);
    if (idx === -1) {
        return null;
    }

    const parsedUrl = new URL(url);
    let main = parsedUrl.host;
    if (!main) {
        main = parsedUrl.pathname.replace('//', '');
    }
    // const pageId = parsedUrl.searchParams.get('pageId');
    const objectId = parsedUrl.searchParams.get('objectId');

    if (objectId) {
        return `${main}?objectId=${objectId}`;
    }

    return main;
};

const isObject = itemId => {
    return itemId.indexOf(PATTERN_OBJECT) >= 0;
};

const openURL = item => {
    item.preventDefault();
    if (historyCopy) {
        historyCopy.forceBack = true;
        historyCopy.push(item.target.getAttribute('href'));
    }
};

const getObjectLink = async (item, itemId) => {
    return new Promise((resolve, reject) => {
        let idx = itemId.indexOf(PATTERN_OBJECT);
        let objectId = itemId.substring(idx + PATTERN_OBJECT.length);
        getObjectClassWithId(objectId, (err, objectClass, objectItem) => {
            let navPath = '';
            let navParams = {};

            if (err) {
                console.log(err);
                reject(err);
            }

            if (objectClass === 'place') {
                navPath = `/floorplan/${objectItem.floorplan}/${objectId}`;
                navParams = {};
            } else {
                navPath = '';
                navParams = {
                    type: 'detail',
                    objectClass,
                    objectId,
                };
            }

            resolve({
                navPath,
                navParams,
            });
        });
    });
};

const getPageLink = (item, itemId) => {
    return new Promise((resolve, reject) => {
        getLocalAppState(async (err, config) => {
            const { eventId } = config;

            const pages = await executeQuery('getPagesWithEventAndName', {
                name: itemId,
                event: eventId,
            });

            if (!pages.length) {
                resolve({
                    navPath: '',
                    navParams: {},
                });
            }

            const [page] = pages;

            if (page) {
                let navPath = '';
                let navParams = {};
                switch (page.kind) {
                    case 'floorplan':
                        navPath = `/floorplan`;
                        navParams = {};
                        break;
                    case 'dailyprogramme':
                        navPath = `/dailyprogramme`;
                        navParams = {};
                        break;
                    case 'webpage':
                    case 'list':
                    default:
                        navPath = '';
                        navParams = {
                            pageType: page.kind,
                            pageId: page.id,
                        };
                        break;
                }

                resolve({
                    navPath,
                    navParams,
                });
            }
        });
    });
};

const CCLink = async (item, match, history, location, addListener) => {
    if (item === null || item.href === null) {
        return null;
    }
    historyCopy = history;
    let link = item.href;
    let itemId = getLink(link);

    //If it is not a CCLink
    if (itemId === null) {
        return null;
    }

    let result = '';
    if (isObject(itemId)) {
        result = await getObjectLink(item, itemId);
    } else {
        result = await getPageLink(item, itemId);
    }

    const navigationData = makeNavigationData(
        match,
        history,
        location,
        result.navPath,
        null,
        result.navParams,
        null,
        null,
    );

    const url = `${navigationData.pathname}${navigationData.search ? navigationData.search : ''}`;

    if (item.nodeType === 1 && addListener) {
        item.setAttribute('href', url);
        item.addEventListener('click', openURL);
    }

    return url;
};

export default CCLink;

export const translateCCLinkUrl = async url => {
    let itemId = getLink(url);
    //If it is not a CCLink
    if (itemId === null) {
        return null;
    }

    let result;
    if (isObject(itemId)) {
        result = await getObjectLink({}, itemId);
    } else {
        result = await getPageLink({}, itemId);
    }

    return result;
};

export const checkCCLinks = async (document, match, history, location) => {
    const anchors = document.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i++) {
        const anchor = anchors[i];
        const href = anchor.href;
        const errors = [];
        if (href.includes('cc://')) {
            try {
                await CCLink(anchor, match, history, location, true);
            } catch (e) {
                errors.push(e);
            }
        } else {
            anchor.target = '_blank';
        }
    }
};

const getDataValueFromUnknownLevel = (arr, key, result) => {
    arr.forEach(function (item) {
        for (let keys in item) {
            if (keys === key) {
                result.push(item[key]);
            } else if (Array.isArray(item[keys])) {
                getDataValueFromUnknownLevel(item[keys], key, result);
            }
        }
    });
    return result;
};

export const CCLinkTransform = (node, match, history, location) => {
    if (node.name === 'a' && node.attribs && node.attribs.href) {
        if (node.attribs.href.includes('cc://')) {
            return (
                <a
                    href={node.attribs.href}
                    onClick={async e => {
                        e.preventDefault();
                        const translatedLink = await CCLink(
                            node.attribs,
                            match,
                            history,
                            location,
                            false,
                        );

                        history.forceBack = true;
                        history.push(translatedLink);
                    }}
                >
                    {node.children[0].data}
                </a>
            );
        } else {
            if (!(node.children && node.children[0] && node.children[0].data)) {
                if (node.children) {
                    return (
                        <a href={node.attribs.href} target="_blank" rel="noreferrer">
                            {node.children.map((child, index) => {
                                return convertNodeToElement(child, index, newNode => {
                                    CCLinkTransform(newNode, match, history, location);
                                });
                            })}
                        </a>
                    );
                }
            }

            return (
                <a href={node.attribs.href} target="_blank" rel="noreferrer">
                    {node.children && node.children[0] && node.children[0].data}
                </a>
            );
        }
    }
};
