import { useEffect } from 'react';
import { create } from 'zustand';
import { executeQuery } from '../services/api/graphQlRepository';
import { getImageSourceAsync, getLocalAppStateAsync } from '../services/api/db';

export const usePagesStore = create((set, get) => ({
    homePage: null,
    homeBannerUrls: {
        loaded: false,
        mobile: null,
        tablet: null,
        web: null,
    },
    setHomePage: homePage => set({ homePage }),
    fetchHomePage: async () => {
        const appState = await getLocalAppStateAsync();
        const [home] = await executeQuery('getPagesWithEventAndKind', {
            event: appState.eventId,
            kind: 'home',
        });

        set({
            homePage: {
                ...home,
                widgets:
                    typeof home.widgets === 'string'
                        ? JSON.parse(home.widgets) || []
                        : home.widgets,
                isStudioPage:
                    (home?.widgets && home?.widgets.length > 0) ||
                    appState?.hasStudioPages ||
                    false,
            },
        });
    },
    pages: [],
    fetchPages: async () => {
        const appState = await getLocalAppStateAsync();
        const allPages = await executeQuery('getPagesWithEventAndName', {
            event: appState.eventId,
        });

        set({
            pages: allPages,
        });
        return allPages;
    },
    getHomePageBannerUrl: async homeBanner => {
        if (!homeBanner?.web?.length) {
            return null;
        }
        const homeBannerUrl = await getImageSourceAsync(homeBanner.web);
        set({
            homeBannerUrl,
        });
    },
    getPageById: id => {
        return get().pages.find(p => p.id === id);
    },
    getPageByName: name => {
        return get().pages.find(p => p.name === name);
    },
}));

export const useHomeBannerUrl = () => {
    const homeBanner = usePagesStore(state => state.homePage?.homeBanner);

    const homeBannerUrl = usePagesStore(state => state.homeBannerUrl);
    const getHomePageBannerUrl = usePagesStore(state => state.getHomePageBannerUrl);

    useEffect(() => {
        if (homeBanner) {
            getHomePageBannerUrl(homeBanner);
        }
    }, [homeBanner]);

    return homeBannerUrl;
};
