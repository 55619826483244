import React, { useEffect, useState } from 'react';
import { useRouteMatch, withRouter } from 'react-router-dom';

import Header from '../HeaderNoSidebarMenu/Header';
import {
    AppContent,
    EventIcon,
    MenuItemMobile,
    StyledPopover,
    TabsContainer,
    Title,
    toolbarTitleStyle,
} from './style/navigationStyle';
import Tabs from '../../components/Tabs';
import { MenuItem } from '@mui/material';
import { Tooltip } from '../../scenes/common/Button';
import * as palette from '../../components/General/Variables';
import { FontIcon } from 'react-md';
import { Flex } from '../../scenes/common/Flex';
import { usePlatformInfo } from '../../scenes/User/components/platformInfo/PlatformInfoContextProvider';
import { useTheme } from '../Theme/ThemeContext';
import toast from 'toasted-notes';
import PlatformInfoNotification from '../../scenes/User/components/platformInfo/PlatformInfoNotification';

const TopNavigation = ({
    title,
    children,
    navigation,
    history,
    location,
    isSidepanelOpened,
    appState,
    setTitle,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorItems, setAnchorItems] = useState([]);
    const [labelMarginLeft, setLabelMarginLeft] = useState(null);
    const [hover, setHover] = useState(false);
    const [anchorTooltipLabel, setAnchorTooltipLabel] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobileNavigationData, setMobileNavigationData] = useState([]);
    const [tabsData, setTabsData] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const { theme } = useTheme();

    const {
        newVersionAvailable,
        updateServiceWorker,
        onServiceWorkerUpdate,
        waitingWorker,
    } = usePlatformInfo();

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (newVersionAvailable) {
            const elem = document.getElementById('update-version-notification');
            if (!elem) {
                toast.notify(
                    ({ onClose }) => (
                        <PlatformInfoNotification
                            onClose={onClose}
                            onUpdate={updateServiceWorker}
                            theme={theme}
                        />
                    ),
                    {
                        position: 'top-right',
                        duration: null,
                    },
                );
            }
        }
    }, [newVersionAvailable]);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const { url } = useRouteMatch();

    const handleClick = (event, items) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setAnchorItems(items);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorItems(null);
    };

    const handleHover = (event, label) => {
        event.stopPropagation();
        const elementPosition = event.target.getBoundingClientRect();
        const tabWidth = elementPosition.width;
        const tooltipWidth = label?.length * 7.05 + 16;
        const emptyWidth = tabWidth - tooltipWidth;
        setLabelMarginLeft(
            tabWidth > tooltipWidth
                ? elementPosition.left + Math.abs(emptyWidth) / 2
                : elementPosition.left,
        );
        setAnchorTooltipLabel(label);
    };

    useEffect(() => {
        if (navigation) {
            const data = [];
            const dataAsMenu = [];
            navigation.forEach(item => {
                data.push({
                    labelIcon: item.webappIcon,
                    labelStudioIcon: item?.icon,
                    onClick: e => {
                        if (item.subItems?.length) {
                            // open submenu
                            handleClick(e, item.subItems);
                        } else {
                            setTitle(item.title);
                            const path = item.screen === 'home' ? '' : `${item.to}`;
                            history.push(`${url}${path}`);
                        }
                    },
                    onMouseEnter: e => {
                        handleHover(e, item.label || item.title);
                    },
                    anchorTooltipLabel,
                });

                if (item?.subItems?.length) {
                    item.subItems.map(it => {
                        dataAsMenu.push(it);
                    });
                } else {
                    dataAsMenu.push(item);
                }
            });
            setTabsData(data);
            setMobileNavigationData(dataAsMenu);
        }
    }, [navigation]);

    useEffect(() => {
        if (location && location.pathname && location.pathname.length) {
            if (navigation) {
                const activeItemIndex = navigation.findIndex(item => {
                    if (
                        location.pathname.includes(`/${item.screen}`) ||
                        location.pathname.includes(item.to)
                    ) {
                        return true;
                    }
                    const foundSubItem = item.subItems?.find(
                        subitem =>
                            location.pathname.includes(`/${subitem.screen}`) ||
                            location.pathname.includes(subitem.to),
                    );
                    if (foundSubItem) {
                        setTitle(foundSubItem.title);
                        return true;
                    }
                    return false;
                });

                setActiveTab(activeItemIndex);
            }
        }
    }, [location]);

    const initialPathName = location.pathname.split('/').slice(0, 2).join('/');

    const isVirtualSession =
        location.pathname &&
        (location.pathname.toLowerCase().indexOf('virtual-session-round') !== -1 ||
            location.pathname.toLowerCase().indexOf('virtual-session') !== -1 ||
            location.pathname.toLowerCase().indexOf('on-demand-poster-room') !== -1 ||
            location.pathname.toLowerCase().indexOf('on-demand-metafusion-room') !== -1 ||
            location.pathname.toLowerCase().indexOf('virtual-beamer-view') !== -1 ||
            location.pathname.toLowerCase().indexOf('chat-presentation') !== -1 ||
            location.pathname.toLowerCase().indexOf('virtual-moderation') !== -1);

    const hideHeader =
        (location.pathname && location.pathname.toLowerCase().indexOf('/exhibitorbooth') > -1) ||
        isVirtualSession;

    return (
        <React.Fragment>
            {!hideHeader && (
                <div>
                    <Header
                        navTitle={title}
                        light={true}
                        newVersionAvailable={newVersionAvailable}
                        updateServiceWorker={updateServiceWorker}
                        onServiceWorkerUpdate={onServiceWorkerUpdate}
                        waitingWorker={waitingWorker}
                    >
                        {appState.pageHeader && (
                            <EventIcon
                                src={appState.pageHeader}
                                marginLeft={'24px'}
                                onClick={() => {
                                    history.push(url);
                                }}
                            />
                        )}
                        <Title
                            style={{
                                marginLeft: !appState.pageHeader ? '16px' : '6px',
                                maxWidth: '10vw',
                                minWidth: '10vw',
                            }}
                        >
                            {title}
                        </Title>
                        {windowWidth > palette.MAX_PHONE_XS_INT ? (
                            <>
                                <TabsContainer>
                                    <Tabs
                                        data={tabsData}
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                        currentTab={activeTab}
                                        topNavigationTabs={true}
                                    />
                                </TabsContainer>
                                <StyledPopover
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    {anchorItems?.map(item => (
                                        <MenuItem
                                            onClick={() => {
                                                if (item.url && item.url.length) {
                                                    window.open(
                                                        item.url,
                                                        '_blank',
                                                        'noopener,noreferrer',
                                                    );
                                                } else if (item) {
                                                    history.push(`${url}${item.to}`);
                                                }

                                                handleClose();
                                            }}
                                            key={item.title}
                                        >
                                            {item.title}
                                        </MenuItem>
                                    ))}
                                    {anchorTooltipLabel && !anchorItems && (
                                        <MenuItem
                                            onClick={() => {
                                                handleClose();
                                            }}
                                            key={anchorTooltipLabel}
                                        >
                                            {anchorTooltipLabel}
                                        </MenuItem>
                                    )}
                                </StyledPopover>
                            </>
                        ) : (
                            <>
                                <Flex
                                    height={'100%'}
                                    alignItems={'center'}
                                    onClick={e => setAnchorEl(e.currentTarget)}
                                >
                                    <FontIcon iconClassName={'material-icons material-icons-round'}>
                                        menu
                                    </FontIcon>
                                </Flex>
                                <StyledPopover
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    {mobileNavigationData?.map(item => (
                                        <MenuItemMobile
                                            onClick={() => {
                                                if (item.url && item.url.length) {
                                                    window.open(
                                                        item.url,
                                                        '_blank',
                                                        'noopener,noreferrer',
                                                    );
                                                } else if (item) {
                                                    history.push(
                                                        item.screen === 'home'
                                                            ? `${url}`
                                                            : `${url}/${item.screen}`,
                                                    );
                                                }

                                                handleClose();
                                            }}
                                            key={item.label || item.title}
                                            active={initialPathName === `${url}/${item.screen}`}
                                        >
                                            <FontIcon
                                                iconClassName={
                                                    'material-icons material-icons-round'
                                                }
                                            >
                                                {item.webappIcon || 'add'}
                                            </FontIcon>
                                            {item.label || item.title}
                                            {initialPathName === `${url}/${item.screen}` && (
                                                <FontIcon
                                                    iconClassName={
                                                        'material-icons material-icons-round'
                                                    }
                                                >
                                                    check
                                                </FontIcon>
                                            )}
                                        </MenuItemMobile>
                                    ))}
                                </StyledPopover>
                            </>
                        )}
                    </Header>
                    <div
                        style={{
                            ...toolbarTitleStyle,
                            fontSize: '21px',
                            width: '100%',
                            background: '#fff',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            height: '57px',
                        }}
                    >
                        {title}
                    </div>
                </div>
            )}
            {hover && (
                <Tooltip bottom={'unset'} top={'64px'} left={`${labelMarginLeft}px`}>
                    {anchorTooltipLabel}
                </Tooltip>
            )}
            <AppContent
                isSidePanelOpened={isSidepanelOpened}
                noHeightCalc={
                    isVirtualSession || location.pathname.includes('exhibitorbooth') ? 1 : 0
                }
                id={'main-container'}
            >
                {children}
            </AppContent>
        </React.Fragment>
    );
};

export default withRouter(TopNavigation);
