import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthUserContext from '../Session/AuthUserContext';
import ThemeContext from '../Theme/ThemeContext';
import { getString } from '../../services/api/store';
import Button, { buttonTypes } from '../../scenes/common/Button';

const BookmarkIcon = props => {
    const { match, history, location } = props;
    const onClick = (authUser, showSignIn) => {
        if (authUser) {
            return props.clickMyCongress;
        } else {
            console.log('LOGGER: showSignIn');
            return e => {
                showSignIn(e, {
                    match,
                    history,
                    location,
                });
            };
        }
    };
    return (
        <AuthUserContext.Consumer>
            {({ authUser, showSignIn }) => {
                if (!props.clickMyCongress) return;
                return (
                    <ThemeContext.Consumer>
                        {({ theme }) => (
                            <React.Fragment>
                                {!theme.embed && !theme.kiosk && (
                                    <Button
                                        type={
                                            props.smallBookmarkButton
                                                ? buttonTypes.GREY
                                                : buttonTypes.GREY_LONG
                                        }
                                        style={
                                            props.smallBookmarkButton
                                                ? {
                                                      width: '56px',
                                                  }
                                                : {}
                                        }
                                        background={theme.primary}
                                        icon={
                                            props.favoriteStatus === 'checked'
                                                ? 'bookmark'
                                                : 'bookmark_border'
                                        }
                                        onClick={onClick(authUser, showSignIn)}
                                        iconCustomColor={
                                            props.favoriteStatus === 'checked' && theme.primary
                                        }
                                        tooltip={
                                            props.favoriteStatus === 'checked'
                                                ? getString(
                                                      'removeFromFavorites',
                                                      'Remove from favorites',
                                                  )
                                                : getString('saveToFavorites', 'Save to favorites')
                                        }
                                        text={
                                            props.smallBookmarkButton
                                                ? ''
                                                : props.favoriteStatus === 'checked'
                                                ? getString('savedButton') || 'Saved'
                                                : getString('saveButton') || 'Save'
                                        }
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </ThemeContext.Consumer>
                );
            }}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(BookmarkIcon);
