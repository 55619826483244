import get from 'lodash/get';
import { executeListQuery } from '../../../services/api/graphQlRepository';

const getHappeningNowPage = async (eventId, pageId) => {
    const results = await executeListQuery('findPages', {
        id: {
            eq: pageId,
        },
    });

    if (results && results.length) {
        return results[0];
    }

    return null;
};

export const fetchUpcomingSessions = async (eventId, pageId, limit, getHappeningNowNextQuery) => {
    const happeningNowPage = await getHappeningNowPage(eventId, pageId);
    const defaultNoSessionsMessage = 'There are no events happening.';

    if (!happeningNowPage) {
        return {
            sessions: [],
            message: defaultNoSessionsMessage,
        };
    }

    let sections = get(happeningNowPage, 'sections', []);
    if (!(sections && sections.length)) {
        sections = get(happeningNowPage, 'params.sections', []);
    }

    let message = get(happeningNowPage, 'subtitle', defaultNoSessionsMessage);
    if (!message) {
        message = get(happeningNowPage, 'params.nfText', defaultNoSessionsMessage);
    }

    let offset = get(happeningNowPage, 'offset', 0);
    if (!offset) {
        offset = get(happeningNowPage, 'params.offset', 0);
    }

    let filterClassifierId = get(happeningNowPage, 'filter.classifierId', null);
    if (!filterClassifierId) {
        filterClassifierId = get(happeningNowPage, 'params.filterClassifierId', null);
    }

    const { ge, le } = getHappeningNowNextQuery(offset);

    let timeslots = await sections.reduce(async (acc, section) => {
        const accumulator = await acc;
        const results = await executeListQuery('findTimeslots', {
            type: {
                eq: section.typeId,
            },
            start: {
                le,
            },
            end: {
                ge,
            },
        });

        return Promise.resolve([...accumulator, ...results]);
    }, Promise.resolve([]));

    if (filterClassifierId) {
        timeslots = timeslots.filter(timeslot =>
            timeslot.classifications.find(cls => cls._id === filterClassifierId),
        );
    }

    if (limit) {
        timeslots = timeslots.splice(0, limit);
    }

    return {
        sessions: timeslots,
        message,
        title: happeningNowPage?.title || happeningNowPage?.params?.title || 'Upcoming sessions',
    };
};
