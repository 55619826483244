import { useMemo } from 'react';
import { useCurrentNavigationItem } from '../stores/EventStore';
import { usePagesStore } from '../stores/PagesStore';

const useCurrentPageByRoute = () => {
    const pages = usePagesStore(state => state.pages);
    const currentNavigationItem = useCurrentNavigationItem();

    return useMemo(() => {
        return pages.find(page => page.id === currentNavigationItem?.pageId);
    }, [pages, currentNavigationItem]);
};

export default useCurrentPageByRoute;
