import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { getObjectPage } from './services/objectPage';
import { getObjectClassWithId } from '../../services/api/db';
import { navigateTo } from '../../components/Navigation/NavigationLink';

const ObjectPage = props => {
    const { match, history, location, pageId } = props;

    const goToObject = useCallback(objectId => {
        getObjectClassWithId(objectId, (err, objectClass) => {
            navigateTo({
                match,
                history,
                location,
                params: {
                    type: 'detail',
                    objectClass,
                    objectId,
                },
                replace: true,
            });
        });
    }, []);

    useEffect(() => {
        const pageOrObjectId = pageId || match.params.objectId;
        const getObjectInfo = () => {
            getObjectPage(pageOrObjectId, (err, result) => {
                if (result && result.params && result.params.objectId) {
                    const objectId = result.params.objectId;
                    goToObject(objectId);
                } else if (pageOrObjectId) {
                    goToObject(pageOrObjectId);
                }
            });
        };
        getObjectInfo();
    }, [pageId]);

    return <React.Fragment key={'link_' + props.location.key}></React.Fragment>;
};
export default withRouter(ObjectPage);
