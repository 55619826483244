import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import {
    checkShouldStartConversation as checkShouldStartConversationAction,
    leaveRooms as leaveRoomsAction,
} from '../../../Talk/actions';
import { getConversationWithRoomId, getServerOnline } from '../../../Talk/selectors';

import ChatContainer from './chat/ChatContainer';

const Chat = ({
    conversation,
    leaveRooms,
    checkShouldStartConversation,
    isHostedSession,
    isPresentation,
}) => {
    const isOnline = useSelector(getServerOnline);

    useEffect(() => {
        if (conversation && isOnline) {
            checkShouldStartConversation(conversation);
        }

        return () => {
            if (conversation) {
                // leaveRooms([conversation]);
                leaveRooms();
            }
        };
    }, [conversation?.id, isOnline]);

    return (
        <ChatContainer
            conversation={conversation}
            chatType={`webapp_chat_virtual`}
            isHostedSession={isHostedSession}
            isPresentation={isPresentation}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    let conversation = ownProps.conversation;
    if (!conversation.jid) {
        const stateConversation = getConversationWithRoomId(state, conversation.roomId);
        if (stateConversation) {
            conversation = stateConversation;
        }
    }

    return {
        conversation,
    };
};

export default connect(mapStateToProps, {
    checkShouldStartConversation: checkShouldStartConversationAction,
    leaveRooms: leaveRoomsAction,
})(Chat);
