import { Box, Grid, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import WidgetDistributor from '../components/WidgetDistributor';
import NewsFeedWidget from '../components/widgets/NewsFeedWidget';

const LandingPageGridLayout = ({ homePage }) => {
    const homePageWidgets = homePage?.widgets || [];
    const newsFeedWidget = homePageWidgets.find(widget => widget.kind === 'news_feed');
    const hasNewsFeedWidget = !!newsFeedWidget;
    const widgetsToDisplay = useMemo(() => {
        if (!hasNewsFeedWidget) {
            //separate the widgets into 2 columns based on the order
            const widgets = homePageWidgets
                .sort((a, b) => a.grid.web.order - b.grid.web.order)
                .reduce(
                    (acc, widget, index) => {
                        if (index % 2 === 0) {
                            acc.left.push(widget);
                        } else {
                            acc.right.push(widget);
                        }
                        return acc;
                    },
                    { left: [], right: [] },
                );
            return widgets;
        }

        return homePageWidgets
            .filter(widget => widget.kind !== 'news_feed')
            .sort((a, b) => a.grid.web.order - b.grid.web.order);
    }, [homePageWidgets, hasNewsFeedWidget]);

    if (hasNewsFeedWidget) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} spacing={2}>
                    {widgetsToDisplay.map(widget => (
                        <Box key={widget._id} pb={2}>
                            <WidgetDistributor widget={widget} />
                        </Box>
                    ))}
                </Grid>

                <Grid item xs={12} sm={6}>
                    <NewsFeedWidget widget={newsFeedWidget} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                {widgetsToDisplay.left.map(widget => (
                    <Box pb={2} key={widget._id}>
                        <WidgetDistributor widget={widget} />
                    </Box>
                ))}
            </Grid>
            <Grid item xs={12} sm={6}>
                {widgetsToDisplay.right.map(widget => (
                    <Box pb={2} key={widget._id}>
                        <WidgetDistributor widget={widget} />
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
};

export default LandingPageGridLayout;
