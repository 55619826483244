import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import AuthUserContext from '../../components/Session/AuthUserContext';
import { getFullProfile } from '../../services/api/eureka';
import PasswordProtect from './PasswordProtect';
import Loader from '../../components/General/Loader';
import AccessDeniedModal from './AccessDeniedModal';
import { usePagesStore } from '../../stores/PagesStore';

function isParsableNumber(str) {
    return !isNaN(str) && str.trim() !== '';
}

const AccessRestrictions = ({ pageId, children, match, history, location }) => {
    const [userData, setUserData] = useState({ userGroups: [], id: null });
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [hasGroupRestrictions, setHasGroupRestrictions] = useState(false);
    const [loading, setLoading] = useState(true);
    const [restrictions, setRestrictions] = useState({
        restrictionGroups: [],
        hasRestriction: false,
        password: '',
        isPasswordProtected: false,
        pageId,
    });
    const [allowed, setAllowed] = useState(false);

    const { authUser, showSignIn } = useContext(AuthUserContext);
    const getPageById = usePagesStore(state => state.getPageById);

    useEffect(() => {
        if (pageId) {
            const page = getPageById(pageId);

            const {
                accessRestrictions,
                password,
                isPasswordProtected,
                accessRestrictionGroups,
                withAccessRestriction,
            } = page;

            let restrictionGroups = accessRestrictionGroups;
            let hasRestriction = withAccessRestriction || !!accessRestrictions;
            if (
                accessRestrictions &&
                isParsableNumber(accessRestrictions.toString()) &&
                !(restrictionGroups && restrictionGroups.length)
            ) {
                restrictionGroups = [parseInt(accessRestrictions)];
            }

            setRestrictions({
                restrictionGroups,
                hasRestriction,
                password: password,
                isPasswordProtected,
                pageId,
            });
        }
    }, [pageId]);

    useEffect(() => {
        const {
            restrictionGroups,
            hasRestriction,
            password,
            isPasswordProtected,
            pageId: restrictedPageId,
        } = restrictions;

        const updateUserProfile = async () => {
            if (userData.id) {
                setLoading(false);
                return;
            }

            try {
                const result = await getFullProfile();
                setUserData(result);
                if (
                    result &&
                    result.id &&
                    !result.userGroups.some(obj => restrictionGroups.includes(obj.id))
                ) {
                    setHasGroupRestrictions(true);
                    setAllowed(false);
                } else {
                    setAllowed(true);
                }
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        };
        const passwordWasEntered = checkIfPasswordWasEnteredForRestrictedPage(restrictedPageId);
        if (password && isPasswordProtected && !passwordWasEntered) {
            setShowPasswordModal(true);
            setLoading(false);
            setAllowed(false);
        } else if (authUser && hasRestriction && restrictionGroups.length > 0) {
            updateUserProfile();
        } else if (hasRestriction && !authUser) {
            setAllowed(false);
            showSignIn(null, { match, history, location, replace: true });
        } else {
            setAllowed(true);
            setLoading(false);
        }
    }, [restrictions, authUser]);

    const checkIfPasswordWasEnteredForRestrictedPage = pageId => {
        if (!pageId) {
            return false;
        }

        try {
            const allowedRestrictedPages =
                JSON.parse(localStorage.getItem('allowedRestrictedPages')) || [];

            return allowedRestrictedPages.find(id => id === pageId);
        } catch (_) {
            // Parse failed
            return false;
        }
    };

    return (
        <>
            {loading && <Loader />}
            {!loading && (
                <>
                    {showPasswordModal && (
                        <PasswordProtect
                            pageId={pageId}
                            password={restrictions.password}
                            eventName={'Page is restricted'}
                            hideShowModal={() => {
                                setShowPasswordModal(false);
                            }}
                        />
                    )}
                    {!showPasswordModal && hasGroupRestrictions && (
                        <AccessDeniedModal notVirtualRoom onClose={() => history.goBack()} />
                    )}
                    {!showPasswordModal && !hasGroupRestrictions && allowed && <>{children}</>}
                </>
            )}
        </>
    );
};

export default withRouter(AccessRestrictions);
