import React, { useState, useRef, useEffect } from 'react';
import throttle from 'lodash/throttle';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import useAnalyticsTracker from '../../features/analytics/hooks/useAnalyticsTracker';
import NavigationBar from '../../components/DetailHeader/NavigationBar';
import * as palette from '../../components/General/Variables';
import ListPage from '../../scenes/ListPage';
import SearchPage from '../../scenes/SearchPage';
import TalkRoom from '../../scenes/TalkRoom';
import SearchResultsPage from '../../scenes/SearchPage/components/searchResults';
import MenuPage from '../../scenes/MenuPage';
import ObjectPage from '../../scenes/ObjectPage';
import SponsorPage from '../../scenes/Sponsor';
import TabsPage from '../../scenes/TabsPage';
import WebPage from '../../scenes/WebPage';
import AttendeeList from '../../scenes/Attendees';
import ProgrammePage from '../../scenes/Programme';
import { navigateTo } from '../Navigation/NavigationLink';
import UpcomingSessionsDetailPage from '../../scenes/LandingPage/containers/UpcomingSessionsDetailPage';
import { MAX_SMALL_PHONE_INT } from '../../components/General/Variables';

const DetailDrawer = styled.div`
    position: fixed;
    top: 64px;
    right: 0;
    width: 480px;
    border-radius: 12px;
    margin: 16px;
    height: calc(100% - 64px - 32px);
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(47, 51, 55, 0.15);
    z-index: 10;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        border-radius: unset;
        z-index: 1000;
        margin: 0;
    }
`;

const Scrollable = styled.section.attrs({
    className: 'scrollable-div',
})`
    height: 100%;
    overflow: auto;
    overflow-y: scroll;
    @media screen and (max-width: ${MAX_SMALL_PHONE_INT}px) {
        padding-bottom: 56px;
    }
`;

const PageSelector = props => {
    const { pageId, pageType, childrenId, match, location, history, installationId } = props;

    const navBar = useRef();
    const header = useRef();
    const [scrollDirection, setScrollDirection] = useState('UP');
    const [scroll, setScroll] = useState(0);
    const [title, setTitle] = useState('');

    useEffect(() => {
        initNavbar();
    }, [pageId, pageType, childrenId]);

    useAnalyticsTracker(installationId, { pageId, pageType, childrenId });

    const _handleScroll = () => {
        if (navBar.current) {
            let newScroll = document.getElementById('scrollable').scrollTop;
            if (scroll > newScroll && scrollDirection !== 'UP' && newScroll > 48) {
                navBar.current.className = 'fixed-navbar';
                header.current.className = 'fixed-navbar-header';
                setScrollDirection('UP');
            } else if (scroll <= newScroll && scrollDirection !== 'DOWN' && newScroll > 48) {
                navBar.current.className = 'auto-navbar';
                header.current.className = 'auto-navbar-header';
                setScrollDirection('DOWN');
            }
            setScroll(newScroll);
        }
    };

    const handleScroll = throttle(_handleScroll, 100);

    const initNavbar = () => {
        if (navBar.current) {
            document.getElementById('root').className = 'noscroll';
            navBar.current.className = 'fixed-navbar';
            header.current.className = 'fixed-navbar-header';
            setScrollDirection('UP');
        }
    };

    let render = true;

    let Rendercomponent = {};

    switch (pageType) {
        case 'now':
        case 'next': {
            Rendercomponent = UpcomingSessionsDetailPage;
            break;
        }
        case 'webpage': {
            Rendercomponent = WebPage;
            break;
        }
        case 'list': {
            Rendercomponent = ListPage;
            break;
        }
        case 'search': {
            Rendercomponent = SearchPage;
            break;
        }
        case 'talkRoom': {
            Rendercomponent = TalkRoom;
            break;
        }
        case 'searchResults': {
            Rendercomponent = SearchResultsPage;
            break;
        }
        case 'menu': {
            Rendercomponent = MenuPage;
            break;
        }
        case 'tabs': {
            Rendercomponent = TabsPage;
            break;
        }
        case 'object': {
            Rendercomponent = ObjectPage;
            break;
        }
        case 'sponsor': {
            Rendercomponent = SponsorPage;
            break;
        }
        case 'attendees': {
            Rendercomponent = AttendeeList;
            break;
        }
        case 'dailyprogramme': {
            Rendercomponent = ProgrammePage;
            break;
        }
        case 'eventwall':
        case 'floorplan': {
            render = false;
            navigateTo({
                match,
                history,
                location,
                path: '/' + pageType,
                replace: true,
            });
            break;
        }
        default: {
            console.error(`Page type: ${pageType} not found on PageSelector!`);
            render = false;
            break;
        }
    }

    // TODO Replace setTitle with event store title

    if (pageId && pageType) {
        return (
            <DetailDrawer>
                <Scrollable id="scrollable" onScroll={handleScroll}>
                    <div ref={navBar}>
                        <NavigationBar
                            goBack={history.goBack}
                            closePath={location.pathname}
                            typeName={title}
                            background={palette.COLOR_WHITE}
                        />
                    </div>
                    {render && (
                        <div ref={header}>
                            <Rendercomponent
                                setTitle={() => {}}
                                pageId={pageId}
                                childrenId={childrenId}
                                detailPage={true}
                                updateTitle={setTitle}
                            />
                        </div>
                    )}
                    {!render && (
                        <div style={{ margin: '25px' }} ref={header}>
                            Unsupported content
                        </div>
                    )}
                </Scrollable>
            </DetailDrawer>
        );
    } else {
        return null;
    }
};

export default withRouter(PageSelector);
