import { create } from 'zustand';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const NAVIGATION_TYPES = {
    SIDE_MENU: 'sideMenu',
    TOP_NAVIGATION: 'iconMenu',
};

// TODO: This is the latest store, but we need to refactor the code to use this new store where possible
export const useEventStore = create(set => ({
    appReady: false,
    navigation: [],
    title: '',
    available: false,
    eventNotFound: false,
    eventNotConfigured: false,
    userNotAttending: false,
    password: null,
    id: null,
    installationId: null,
    eurekaOnly: false,
    attendingOnly: false,
    timezoneSelection: 'choice',
    hiddenPassword: null,
    navigationType: NAVIGATION_TYPES.SIDE_MENU,
    navigationItemsMobile: null,
    metaFusionClientId: null,
    metaFusionEnabled: false,
    appState: {},
    theme: {},
    loading: true,
    setLoading: loading => set({ loading }),
    updateEventState: updates => set(state => ({ ...state, ...updates })),
    setTitle: title => set({ title }),
}));

export const useCurrentNavigationItem = () => {
    const navigation = useEventStore(state => state.navigation);
    const location = useLocation();

    return useMemo(() => {
        return navigation.find(navItem => {
            // Remove any query parameters
            const cleanPath = location.pathname.split('?')[0];
            // Get everything after eventName/
            const afterEventNamePath = cleanPath.split('/').slice(3).join('/');
            // Check if the page route matches the last part
            return navItem.to && navItem.to.includes(afterEventNamePath);
        });
    }, [navigation, location.pathname]);
};
