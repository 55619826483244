import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Cell, Grid } from 'react-md';
import Iframe from '../../components/General/Iframe';
import { getWebPageByPage } from './services/webPage';
import AccessRestrictions from '../AccessRestrictions';

const ListContainer = styled(Grid)`
    margin: 0;
    padding: 0;
`;

const ListCell = styled(Cell)`
    margin: 0;
    background-color: white;
    width: 100%;
`;

const Scrollable = styled.section.attrs({
    className: '',
})`
    height: 100%;
    overflow: auto;
`;

const WebPage = props => {
    const [data, setData] = useState({
        html: '',
        title: '',
    });

    const { setTitle, updateTitle, pageId, detailPage, fromTabs } = props;

    useEffect(() => {
        if (!props.pageId) {
            return;
        }
        if (setTitle) {
            setTitle();
        }

        getWebPageByPage(props.pageId, (err, webpage, page) => {
            if (page) {
                let htmlToRender = '';
                let webPageName = '';
                if (err || !webpage) {
                    htmlToRender = 'Webpage not found';
                } else {
                    const { html, name } = webpage;

                    htmlToRender = html;
                    webPageName = name;
                }

                htmlToRender = `<style type='text/css'> .container{width:auto !important;}</style>${htmlToRender}`;

                setData({
                    ...data,
                    title: webPageName || '',
                    html: htmlToRender,
                });

                if (updateTitle) {
                    updateTitle(webPageName);
                }
            }
        });
    }, [pageId, setTitle]);

    const { html } = data;

    return (
        <AccessRestrictions pageId={pageId}>
            {(detailPage || fromTabs) && <Iframe html={html} src="" />}
            {!(detailPage || fromTabs) && (
                <div>
                    <ListContainer>
                        <ListCell size={12} tabletSize={8}>
                            <Scrollable>
                                <Iframe html={html} src="" />
                            </Scrollable>
                        </ListCell>
                    </ListContainer>
                </div>
            )}
        </AccessRestrictions>
    );
};

export default WebPage;
