import moment from 'moment';
import async from 'async';
import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import sortBy from 'lodash/sortBy';
import pull from 'lodash/pull';
import get from 'lodash/get';
import {
    executeListQuery,
    executeQuery,
    getItem,
    getItemAsync,
} from '../../../services/api/graphQlRepository';
import Auth from '../../../services/api/auth';
import AppointmentService from '../../Appointments/services/AppointmentService';
import { APPOINTMENT_PARTICIPANT_STATUS } from '../../Appointments/constants';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';

const asyncForEach = async (array = [], callback) => {
    for (const item of array) {
        await callback(item);
    }
};

const getDays = async function (schedule, hasStudioReleases, getUtcToSelectedTimezone) {
    if (schedule) {
        const root = schedule;
        if (hasStudioReleases) {
            const timeslots = await executeListQuery(
                'findTimeslots',
                {
                    schedule: {
                        eq: root,
                    },
                },
                false,
                false,
                null,
                1,
            );
            const firstLevel = timeslots.filter(slot => {
                const { start, parent } = slot;
                return start && start.length > 0 && !parent;
            });
            const sorted = sortBy(firstLevel?.length ? firstLevel : [], ['start']);
            const grouped = groupBy(sorted, item => {
                const { start } = item;
                return getUtcToSelectedTimezone(start).startOf('day').toISOString();
            });
            return Object.keys(grouped).map(key => {
                return {
                    id: key,
                    name: key,
                    start: key,
                };
            });
        } else {
            const timeslots = await executeQuery(
                'getTimeslotsWithType',
                {
                    type: root,
                },
                false,
                1,
            );
            return sortBy(timeslots?.length ? timeslots : [], ['start']);
        }
    } else {
        console.log('no programme page found or root is not configured');
        return [];
    }
};

const mapNoGroupedToObject = (group, groupId) => {
    switch (groupId) {
        case 'nonLocated':
            return {
                group: {
                    id: 'noPlace',
                    name: 'No specified location',
                },
                items: sortBy(group[groupId], ['start']),
            };
        case 'nonClassified':
            return {
                group: {
                    id: 'noClassifier',
                    name: 'No specified classifier',
                },
                items: sortBy(group[groupId], ['start']),
            };
        case 'nonGrouped':
            return {
                group: {
                    id: 'noGroup',
                },
                items: sortBy(group[groupId], ['start']),
            };
        case 'other':
            return {
                group: {
                    id: 'other',
                    name: 'Other',
                },
                items: sortBy(group[groupId], ['start']),
            };
        default:
            return {
                group: {
                    id: 'noPlace',
                    name: 'No specified location',
                },
                items: sortBy(group[groupId], ['start']),
            };
    }
};

const groupByPlace = sessions => {
    return groupBy(sessions, session => {
        if (session) {
            if (session.objectClass === 'Appointment') {
                return 'other';
            }

            return session.locations && session.locations.length
                ? session.locations[0]._id
                : 'nonLocated';
        }

        return 'noSession';
    });
};

const groupByClassifier = (sessions, classifiers) => {
    // Group items by classifier id
    const group = classifiers.reduce((acc, cls) => {
        const sessionsForClassifier = sessions.filter(
            session =>
                session.classifications && session.classifications.find(c => c._id === cls.id),
        );

        // Remove the items that has been already grouped
        sessions = sessions.filter(
            session => !sessionsForClassifier.find(sfc => sfc.id === session.id),
        );

        acc[cls.id] = sessionsForClassifier;

        return acc;
    }, {});

    group.other = sessions.filter(session => session.objectClass === 'Appointment');
    sessions = sessions.filter(session => session.objectClass !== 'Appointment');
    group.nonClassified = sessions;

    return group;
};

const shouldDisplayAppointment = appointment =>
    appointment &&
    appointment.members &&
    appointment.members.length &&
    appointment.members[0].AppointmentGroup &&
    (appointment.members[0].AppointmentGroup.status === APPOINTMENT_PARTICIPANT_STATUS.WAITING ||
        appointment.members[0].AppointmentGroup.status === APPOINTMENT_PARTICIPANT_STATUS.ACCEPTED);

const getUserAppointmentsFromDay = async day => {
    if (!Auth.isUserAuthenticated()) {
        return [];
    }

    const user = Auth.getUser();
    const appointments = await AppointmentService.getAllAppointments(user.id);
    const appointmentsToDisplay = appointments.filter(shouldDisplayAppointment);
    const dayStart = moment.utc(day.start);
    const dayEnd = moment.utc(day.end);

    const filteredAppointments = appointmentsToDisplay.filter(appointment => {
        let appointmentStart = moment.utc(appointment.start);

        if (!appointmentStart.isValid()) {
            appointmentStart = moment.unix(appointment.start);
        }

        return appointmentStart.isSameOrAfter(dayStart) && appointmentStart.isSameOrBefore(dayEnd);
    });

    // Convert unix time to string format
    return filteredAppointments.map(app => ({
        ...app,
        start: moment.unix(app.start).utc().toString(),
        end: moment.unix(app.end).utc().toString(),
        type: 'appointment',
    }));
};

var getSessionsFromDay = async function (
    settings,
    day,
    hasStudioReleases,
    getUtcToSelectedTimezone,
    next,
) {
    let sessions = [];
    let {
        groupingOption,
        groupingValue,
        skipTimeframes,
        schedule: root,
        classifierId,
    } = settings || {
        groupingOption: null,
        groupingValue: null,
        skipTimeframes: false,
        schedule: '',
        classifierId: '',
    };

    if (hasStudioReleases) {
        const startFrame = moment.utc(day.start).toISOString();
        const endFrame = moment.utc(day.start).add(24, 'hours').toISOString();
        sessions = await executeListQuery(
            'findTimeslots',
            {
                schedule: {
                    eq: root,
                },
                start: {
                    ge: startFrame,
                },
                end: {
                    lt: endFrame,
                },
            },
            false,
            false,
            null,
            1,
        );
        sessions = sessions.filter(item => !item.parent);
    } else {
        const timeframes = await executeListQuery(
            'findTimeslots',
            {
                parent: {
                    eq: day.id,
                },
            },
            false,
            false,
            null,
            1,
        );

        // // Merge appointments (eureka entity) with other sessions
        // const appointments = await getUserAppointmentsFromDay(day);
        // sessions.push(...appointments);

        if (!timeframes.length) {
            return next('no timeframes found');
        }

        if (!skipTimeframes) {
            for (let index = 0; index < timeframes.length; index++) {
                const slots = await executeListQuery(
                    'findTimeslots',
                    {
                        parent: {
                            eq: timeframes[index]['id'],
                        },
                    },
                    false,
                    false,
                    null,
                    1,
                );

                sessions.push(...slots);
            }
        } else {
            sessions.push(...timeframes);
        }
    }

    if (classifierId) {
        sessions = sessions.filter(item =>
            item.classifications?.find(cls => cls._id === classifierId),
        );
    }

    const result = [];
    const sortedSessionsByEnd = sessions.sort((a, b) => new Date(b.end) - new Date(a.end));
    const last = (sortedSessionsByEnd && sortedSessionsByEnd[0]) || maxBy(sessions, o => o.end);
    const first = minBy(sessions, o => o.start);

    const timerange = {
        start: first && getUtcToSelectedTimezone(first.start).hour(),
        end: last && getUtcToSelectedTimezone(last.end).hour() + 1,
    };

    if (timerange.start > timerange.end) {
        timerange.end = timerange.end + 24;
    }

    let classifiers = [];
    let group;
    if (groupingOption === 'byPlace') {
        group = groupByPlace(sessions);
    } else if (groupingOption === 'noGrouping') {
        group = { nonGrouped: sessions };
    } else if (groupingOption === 'byClassifier') {
        classifiers = await executeQuery(
            'getClassifiersWithType',
            { type: groupingValue },
            false,
            2,
        );
        group = groupByClassifier(sessions, classifiers);
    } else {
        // Default case: group by location
        group = groupByPlace(sessions);
    }
    const groups = Object.keys(group);

    async.each(
        groups,
        function (groupId, cb) {
            // if no group defined, return early
            if (
                groupId === 'nonLocated' ||
                groupId === 'nonClassified' ||
                groupId === 'nonGrouped' ||
                groupId === 'other'
            ) {
                const object = mapNoGroupedToObject(group, groupId);
                result.push(object);

                return cb();
            }

            if (groupingOption === 'byPlace' || !groupingOption) {
                getItem('places', groupId, (err, place) => {
                    const object = {
                        group: {
                            id: groupId || 'noPlace',
                            name: place && place.name ? place.name : 'No specified location',
                            orderingName: place && place.orderingName,
                        },
                        items: sortBy(group[groupId], ['start']),
                    };
                    result.push(object);

                    return cb(err);
                });
            } else if (groupingOption === 'byClassifier') {
                const classifier = classifiers.find(cl => cl.id === groupId);

                const object = {
                    group: {
                        id: groupId || 'noClassifier',
                        name:
                            classifier && classifier.name
                                ? classifier.name
                                : 'No specified classifier',
                        orderingName: classifier && classifier.orderingName,
                    },
                    items: sortBy(group[groupId], ['start']),
                };
                result.push(object);

                return cb(null);
            } else {
                return cb('Invalid grouping option');
            }
        },
        err => {
            next(
                err,
                sortBy(result, [
                    function (elem) {
                        return elem.group.orderingName;
                    },
                    function (elem) {
                        return elem.group.name;
                    },
                ]),
                timerange,
            );
        },
    );
};

async function getFilterTypes(webFilters) {
    if (webFilters && webFilters.length > 0) {
        const webappfilters = webFilters.filter(n => n);
        return await Promise.all(
            webappfilters.map(async filter => {
                const type = await getItemAsync('types', filter);
                if (type && type.singular) {
                    return {
                        name: type.singular,
                        type: filter,
                    };
                }
            }),
        );
    } else {
        return [];
    }
}

function getGroupingOptions(page, callback) {
    if (page && page.params && page.params.groupingOption) {
        callback(null, {
            groupingOption: page.params.groupingOption,
            groupingValue: page.params.groupingValue,
        });
    } else {
        callback(null, {});
    }
}

const keysToSearchFor = ['name', 'subNameDetail', 'subNameList', 'searchTerms', 'info'];

const searchTermsMatchesData = (obj, searchQuery, keys = keysToSearchFor) => {
    let matches = false;

    const cleanTerm = searchQuery.replace(/([*+?^${}()|[]\/\\])/gi, '').toLowerCase();
    const queryArray = pull(cleanTerm.split(' '), '', undefined, null, '.');

    for (let i = 0; i < keys.length; i += 1) {
        const str = get(obj, keys[i], '') || '';
        matches =
            matches ||
            queryArray.every(search => str.toLowerCase().indexOf(search.toLowerCase()) !== -1);

        if (matches) {
            break;
        }
    }

    return matches;
};

const searchTermsMatchesChildren = async (session, searchQuery) => {
    try {
        let match = false;
        const children = await executeListQuery(
            'findTimeslots',
            {
                parent: {
                    eq: session.id,
                },
            },
            false,
            false,
            null,
            1,
        );
        const posters = await executeListQuery(
            'findProgramelements',
            {
                parent: {
                    eq: session.id,
                },
            },
            false,
            false,
            null,
            1,
        );

        if (children && children.length) {
            children.forEach(t => {
                const isMatch = searchTermsMatchesData(t, searchQuery, ['name', 'searchTerms']);
                if (!match) {
                    match = isMatch;
                }
            });
        }

        if (!match && posters && posters.length) {
            posters.forEach(t => {
                const isMatch = searchTermsMatchesData(t, searchQuery, ['name', 'searchTerms']);
                if (!match) {
                    match = isMatch;
                }
            });
        }

        return match;
    } catch (error) {
        return false;
    }
};

const searchTermsMatchesRoles = async (session, searchQuery) => {
    try {
        let match = false;
        const { roles } = session;
        if (!(roles && roles.length)) {
            return match;
        }

        await asyncForEach(roles, async role => {
            let roleObject = await getItemAsync('persons', role.actor);
            if (!roleObject) {
                roleObject = await getItemAsync('institutions', role.actor);
            }
            if (roleObject) {
                const isMatch = searchTermsMatchesData(roleObject, searchQuery, [
                    'name',
                    'searchTerms',
                ]);
                if (!match) {
                    match = isMatch;
                }
            }
        });

        return match;
    } catch (error) {
        return false;
    }
};

async function filterProgram(filter, data, favorites, marked) {
    const { filters, text } = filter || { filters: {}, text: '' };
    const needsFitering = data && data.length && (!isEmpty(filters) || (text && text.length > 0));
    if (needsFitering) {
        let result = [];

        // for each group, iterate its items
        await asyncForEach(data, async locationItem => {
            let newItem = {};
            newItem.group = locationItem.group;
            newItem.items = [];

            // for each program item of a group
            await asyncForEach(locationItem.items, async item => {
                let pass = true;

                if (favorites && favorites.length) {
                    let filterFavorite = find(favorites, function (f) {
                        return f.id === item.id && f.action === 'Add';
                    });
                    if (!filterFavorite && marked && marked.length) {
                        filterFavorite = find(marked, function (m) {
                            return m === item.id;
                        });
                    }
                    pass = filterFavorite ? true : false;
                }

                if (filter.text && filter.text.length > 1) {
                    const matchesObject = searchTermsMatchesData(
                        item,
                        filter.text,
                        keysToSearchFor,
                    );
                    // const hasChildrenThatPass = await searchTermsMatchesChildren(item, filter.text);
                    // const hasRolesThatPass = await searchTermsMatchesRoles(item, filter.text);
                    // const matches = matchesObject || hasChildrenThatPass || hasRolesThatPass;
                    pass = pass && matchesObject;
                }

                if (!isEmpty(filter.filters)) {
                    let allClassifierIds = [];
                    values(filter.filters).forEach(group => {
                        const ids = group.map(cl => cl.id);
                        allClassifierIds = [...allClassifierIds, ...ids];
                    });
                    allClassifierIds.forEach(cId => {
                        const classifications = item?.classifications || [];
                        pass = pass && classifications.find(c => cId.indexOf(c._id) > -1);
                    });
                }

                if (pass) {
                    newItem.items.push(item);
                }
            });

            result.push(newItem);
        });

        return result;
    } else {
        return data;
    }
}

export { getDays, getSessionsFromDay, getFilterTypes, getGroupingOptions, filterProgram };
