import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Feed from '../../Feed';
import * as palette from '../../../components/General/Variables';
import { getLocalAppStateAsync } from '../../../services/api/db';
import TilesAndWidgets from '../components/TilesAndWidgets';
import { parseTileGroups } from '../service/parsing';
import LandingPageGridLayout from './LandingPageGridLayout';
import Stack from '@mui/material/Stack';
import HomePageBanner from '../components/HomePageBanner';
import { usePagesStore } from '../../../stores/PagesStore';
import { Box } from '@mui/material';

const Screen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Container = styled.div`
    padding: 24px;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};

    & > * {
        flex: 0 ${props => (props.hasNews && props.isDesktop ? '50%' : '100%')};
        max-width: ${props => (props.hasNews && props.isDesktop ? '50%' : '100%')};
        height: fit-content;
    }
`;

const LandingPage = props => {
    const [hasNews, setHasNews] = useState(true);
    const [tileGroups, setTileGroups] = useState([]);
    const homePage = usePagesStore(state => state.homePage);
    const fetchHomePage = usePagesStore(state => state.fetchHomePage);

    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;

    useEffect(() => {
        (async () => {
            const appState = await getLocalAppStateAsync();

            if (typeof appState.hasNews === 'boolean') {
                setHasNews(appState.hasNews);
            }

            if (appState.tileGroups) {
                setTileGroups(parseTileGroups(appState.tileGroups));
            }
            fetchHomePage();
        })();
    }, [fetchHomePage]);

    if (homePage?.isStudioPage) {
        return (
            <Stack
                style={{
                    backgroundColor: homePage?.homeBgColor || '#f0f0f0',
                }}
                alignItems="center"
                width="100%"
            >
                <HomePageBanner homePage={homePage} />
                <Box width="100%" maxWidth="1440px" padding={3}>
                    <LandingPageGridLayout homePage={homePage} {...props} />
                </Box>
            </Stack>
        );
    }

    return (
        <Screen>
            <Container hasNews={hasNews} isDesktop={isDesktop}>
                {tileGroups.length > 0 && (
                    <TilesAndWidgets tileGroups={tileGroups} hasNews={hasNews} {...props} />
                )}
                {hasNews && <Feed {...props} />}
            </Container>
        </Screen>
    );
};

export default LandingPage;
