import React, { useMemo } from 'react';
import UpcomingSessionsDetailPage from '../../containers/UpcomingSessionsDetailPage';
import styled from 'styled-components';
import { usePagesStore } from '../../../../stores/PagesStore';

const WidgetContainer = styled.div`
    border-radius: 8px;
    background-color: white;
    padding: 24px;
    height: 336px;
`;

const HappeningNowWidget = ({ widget }) => {
    const getPageByName = usePagesStore(state => state.getPageByName);

    const pageId = useMemo(() => {
        const nowPage = getPageByName(widget?.happeningNowName);
        return nowPage?.id || '';
    }, [widget, getPageByName]);

    return (
        <WidgetContainer style={{ boxShadow: '0 3px 6px 0 rgba(0,0,0,0.3)' }}>
            <UpcomingSessionsDetailPage pageId={pageId} placeholderWidth={'30%'} />
        </WidgetContainer>
    );
};

export default HappeningNowWidget;
