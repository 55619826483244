import React, { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import { Divider, List } from 'react-md';
import NavigationLink from '../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import noFavoritesImg from '../../assets/images/no-favorites.png';
import noResultsImg from '../../assets/images/no-results.png';
import Loader from '../../components/General/Loader';
import Search from '../../components/General/Search';
import * as palette from '../../components/General/Variables';
import ClassifierIcon from '../../components/Icons/ClassifierIcon';
import ObjectListItem from '../../components/ObjectListItem';
import { getLocalAppState } from '../../services/api';
import { getString } from '../../services/api/store';
import { filterByFavorites, getListElements } from './services/listPage';
import AccessRestrictions from '../AccessRestrictions';
import { makeItemAsync } from '../../services/api/data';
import Auth from '../../services/api/auth';
import { executeQuery, getItemAsync } from '../../services/api/graphQlRepository';
import Button, { buttonTypes } from '../common/Button';
import BrandingBanner from '../../components/General/BrandingBanner';
import sortBy from 'lodash/sortBy';
import Box from '@mui/material/Box';
import Slideshow from '../ExhibitorPage/containers/Slideshow';

const OFFSET = 50;
const MAX_WIDTH_GRID_LAYOUT = 1050;

const EmptyState = styled.div`
    text-align: center;
    padding-top: 50px;

    h4 {
        font-size: 18px;
        font-weight: 500;
        color: ${palette.COLOR_TEXT};
    }

    p {
        font-size: 14px;
        color: ${palette.COLOR_TEXT};
    }
`;

const DividerStyled = styled(Divider)`
    margin-bottom: 0px !important;
    margin-top: 0px !important;
`;
const ListStyled = styled(List)`
    width: 100%;
`;

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
    min-width: 0;
`;

const SearchContainer = styled.div`
    margin-right: 8px;
    flex: auto;
    transition: width 2s linear;
`;

const MoreButtonContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: calc(50% - 40px);
`;
const PlaceholderImage = styled.div`
    background-image: url(${props => (props.myprogram ? noFavoritesImg : noResultsImg)});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 500px;
`;
const ListInformation = styled.div`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    width: 100%;
    text-align: center;
    margin-top: 40px;
`;

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 0 0.25fr;
  grid-template-areas: '. search .';
  height: 72px;
  padding: 16px !important;
  position: relative;
  background-color: ${palette.COLOR_WHITE};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05),
  0px 2px 4px rgba(0, 0, 0, 0.15);
  ${props =>
      props.issticky &&
      `
        position: sticky;
        top: ${props.top};
        z-index: 1;
    `} @media only screen and(max-width: ${palette.MAX_DESKTOP}) {
  padding: 0 24px;
}

  @media only screen and (max-width: ${palette.MIN_DESKTOP}) {
    padding: 0 64px;
  }

  @media only screen and (max-width: ${palette.MAX_PHONE}) {
    grid-template-columns: 1fr;
    grid-template-areas: 'search';
    padding: 0;
  }

  @media only screen and (max-width: ${palette.MIN_PHONE_XS}) {
    padding: 0 4px;
    width: unset;
  }
`;

const FiltersActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    grid-area: search;
`;

const ListsWrapper = styled.div`
    margin: 40px auto;
    max-width: ${MAX_WIDTH_GRID_LAYOUT}px;

    @media only screen and (max-width: ${palette.MIN_DESKTOP}) {
        margin: 24px auto;
        max-width: 769px;
    }

    @media only screen and (max-width: ${palette.MIN_TABLET}) {
        max-width: 738px;
    }

    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        margin: 16px auto 0 auto;
        max-width: 342px;
    }
`;

const GridLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    width: 100%;

    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        justify-content: space-between;
    }

    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        justify-content: space-around;
    }
`;

const ClassifierSectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
    font-family: 'Cabin', sans-serif;
`;

const EMPTY_LIST_FAVORITE_TITLE = 'Nothing favourited yet...';
const EMPTY_LIST_FAVORITE_SUBTITLE =
    'Click the bookmark icon at the top right to add an item to your personal programme.';
const EMPTY_LIST_FILTER_TITLE = 'Nothing here…';
const EMPTY_LIST_FILTER_SUBTITLE = 'Change your keywords or filters.';

const ListItem = ({ item, classifierIcon, insetDivider, listType, cardView }) => (
    <span>
        <ObjectListItem
            item={item}
            type={(item && item.category) || listType}
            icon={classifierIcon}
            cardView={cardView}
        />
        {!cardView && !item.time && <DividerStyled inset={insetDivider} />}
    </span>
);

const ListItemWithLik = ({
    i,
    path,
    params,
    styles,
    item,
    classifierIcon,
    insetDivider,
    data,
    historyState,
}) => {
    const [navParams, setNavParams] = useState({});

    useEffect(() => {
        const checkParams = async () => {
            if (params && params.objectClass === 'role') {
                const typeObj = await getItemAsync('types', item.type);
                return setNavParams({
                    ...params,
                    objectClass: typeObj?.target.toLowerCase(),
                });
            } else {
                setNavParams(params);
            }
        };

        checkParams();
    }, [params]);

    return (
        <LinkStyled
            key={'link_' + i}
            path={path}
            params={navParams}
            style={styles}
            state={historyState}
        >
            <ListItem
                item={item}
                classifierIcon={classifierIcon}
                insetDivider={insetDivider}
                listType={data.listType}
                cardView={data.gridLayout}
            />
        </LinkStyled>
    );
};

const ListPage = props => {
    const [data, setData] = useState({
        listItems: [],
        displayItems: [],
        listType: '',
        closeLinkPath: props.closeLink ? props.closeLink : '/programme',
        currentIndex: 0,
        myProgram: 'all',
        searchStr: '',
        listInfo: '',
        gridLayout: false,
        listByClassifiers: [],
        classifierByType: [],
        slideshow: [],
    });
    const [loading, setLoading] = useState(true);
    const [banner, setBanner] = useState(null);
    const [titleOnBanner, setTitleOnBanner] = useState(null);
    const [subtitleOnBanner, setSubtitleOnBanner] = useState(null);

    const isAuthenticated = Auth.isUserAuthenticated();

    const navigation = useRef(null);
    let TEXT_EMPTY_LIST_FAVORITE_TITLE =
        getString('noFavouriteItemsTitle') || EMPTY_LIST_FAVORITE_TITLE;
    let TEXT_EMPTY_LIST_FAVORITE_SUBTITLE =
        getString('noFavouriteItemsDescription') || EMPTY_LIST_FAVORITE_SUBTITLE;
    let TEXT_EMPTY_LIST_FILTER_TITLE = getString('noFilteredItemsTitle') || EMPTY_LIST_FILTER_TITLE;
    let TEXT_EMPTY_LIST_FILTER_SUBTITLE =
        getString('noFilteredItemsDescription') || EMPTY_LIST_FILTER_SUBTITLE;
    const LOAD_MORE_BUTTON = getString('loadMore') || 'LOAD MORE';

    const insetDivider =
        data.listType === 'institution' || data.listType === 'person' || data.listType === 'place';

    let listItems = data.displayItems;

    const displayMore = data.listItems.length >= data.currentIndex + OFFSET;
    const isMobile = window.innerWidth < palette.MIN_TABLET_INT;
    const { detailPage } = props;

    useEffect(() => {
        setLoading(true);
        getListInfo({
            currentIndex: 0,
            searchStr: '',
            myProgram: 'all',
        });

        getLocalAppState((err, localAppState) => {
            if (err || !localAppState) {
                return;
            }

            navigation.current = localAppState.navigation;
        });
    }, [props.pageId]);

    const addIsExhibitorField = async items => {
        return await Promise.all(
            items.map(async item => {
                const typeObj = await getItemAsync('types', item.type);
                item.isExhibitor = typeObj && typeObj.singular === 'Exhibitor';
                if (
                    typeObj &&
                    typeObj.target &&
                    (typeObj.target.toLowerCase() === 'institution' ||
                        typeObj.target.toLowerCase() === 'institutions')
                ) {
                    const exhibitor = await makeItemAsync(item.id, 'institution');
                    item.showClickBooth =
                        exhibitor && exhibitor.typeParams && exhibitor.typeParams.showClickBooth;
                }
                return item;
            }),
        );
    };

    const getListGroupedByClassifiers = (classifierByType, initialItems) => {
        return classifierByType
            ?.filter(cls => {
                const itemWithCls = initialItems?.filter(it =>
                    it.classifications?.find(c => c._id === cls.id),
                );
                if (itemWithCls.length) {
                    return {
                        classifierName: cls.name,
                        items: itemWithCls,
                    };
                }
            })
            .map(cls => {
                const itemWithCls = initialItems?.filter(it =>
                    it.classifications?.find(c => c._id === cls.id),
                );
                if (itemWithCls.length) {
                    return {
                        classifierName: cls.name,
                        items: itemWithCls,
                    };
                }
            });
    };

    const getListInfo = initialData => {
        getListElements(props.pageId, null, async (err, result, page) => {
            if (err) {
                console.log(err);

                return;
            }
            const listInfo =
                (page && page.subtitle) ||
                (page.params && page.params.info ? page.params.info : '') ||
                '';

            if (result) {
                const banner = page?.params?.bannerImage || page?.pageBanner;
                if (banner) {
                    setBanner(banner);
                }
                let items = result && result.items && result.items.slice(0, OFFSET);

                // In grid layout there can be items with two sizes: normal and large
                // The large sized items should be displayed at the top of the list

                const layout = page?.params?.layout || page?.listLayout;
                const showPageNameInBanner =
                    page?.params?.showPageNameInBanner || page?.showPageNameInBanner;

                const title = page?.params?.title || page?.title;

                if (layout === 'grid') {
                    const largeCardItems = items.filter(
                        item => item.params && item.params.isLargeCard,
                    );
                    const normalCardItems = items.filter(
                        item => !(item.params && item.params.isLargeCard),
                    );
                    items =
                        largeCardItems.length || normalCardItems.length
                            ? Array.from(new Set(largeCardItems.concat(normalCardItems)))
                            : result.items.slice(0, OFFSET);
                }
                if (showPageNameInBanner) {
                    setTitleOnBanner(title);
                    setSubtitleOnBanner(listInfo);
                    props.setTitle('');
                } else {
                    props.setTitle();
                }

                if (items && items.length > 0) {
                    items = await addIsExhibitorField(items);
                }

                let listByClassifiers = [];
                let classifierByType;

                const groupByTagType =
                    page?.groupByTagType || page.params.filter?.groupListByClassifierTypeId;

                if (groupByTagType) {
                    classifierByType = await executeQuery('getClassifiersWithType', {
                        type: groupByTagType,
                    });
                    if (classifierByType?.length) {
                        classifierByType = sortBy(classifierByType, ['orderingName', 'name']);
                    }
                    listByClassifiers = getListGroupedByClassifiers(classifierByType, result.items);
                }

                if (listByClassifiers.length) {
                    items = listByClassifiers.slice(0, OFFSET);
                }

                setData({
                    ...data,
                    ...initialData,
                    displayItems: items,
                    listItems: result.items,
                    listType: result.type,
                    listInfo,
                    hasSearch: result.hasSearch,
                    gridLayout: layout === 'grid',
                    listByClassifiers,
                    classifierByType,
                    slideshow: page?.slideshow || [],
                });
                setLoading(false);
                if (props.updateTitle) {
                    props.updateTitle(result.title);
                }
            } else {
                setData({
                    ...data,
                    ...initialData,
                    listInfo,
                });
                setLoading(false);
            }
        });
    };

    const loadMore = () => {
        const newIndex = data.currentIndex + OFFSET;
        const listItems = data.listByClassifiers?.length ? data.listByClassifiers : data.listItems;
        const newItems = listItems.slice(newIndex, newIndex + OFFSET);
        const items = data.displayItems.concat(newItems);
        setData({
            ...data,
            displayItems: items,
            currentIndex: newIndex,
        });
    };

    const applyFilters = (search, favorites, initialData) => {
        setLoading(true);
        getListElements(props.pageId, search, async (err, result) => {
            if (err) {
                console.log(err);
                setLoading(false);
            } else {
                let filtered = await addIsExhibitorField(result.items);
                if (favorites === 'my_program') {
                    filterByFavorites(filtered, (err, favoriteItems) => {
                        if (favoriteItems && favoriteItems.length) {
                            const items = favoriteItems.slice(0, OFFSET);
                            const favoriteListGropedByClassifier = favoriteItems.map(fav =>
                                data.listByClassifiers?.filter(it =>
                                    it.items.find(i => i.id === fav.id),
                                ),
                            );
                            setData({
                                ...data,
                                ...initialData,
                                listItems: favoriteItems,
                                displayItems: items,
                                currentIndex: 0,
                                listByClassifiers:
                                    favoriteListGropedByClassifier &&
                                    favoriteListGropedByClassifier[0],
                            });
                            setLoading(false);
                        } else {
                            setData({
                                ...data,
                                ...initialData,
                                listItems: [],
                                listByClassifiers: [],
                            });
                            setLoading(false);
                        }
                    });
                } else {
                    const items = filtered.slice(0, OFFSET);
                    const filteredListGropedByClassifier = getListGroupedByClassifiers(
                        data.classifierByType,
                        filtered,
                    );
                    setData({
                        //  TODO: This could be refined. Do we have a regular expression somewhere?
                        ...data,
                        ...initialData,
                        listItems: filtered,
                        displayItems: items,
                        currentIndex: 0,
                        listType: result.type,
                        listByClassifiers: filteredListGropedByClassifier,
                    });
                    setLoading(false);
                }
            }
        });
    };

    const _onSearch = str => {
        applyFilters(str, data.myProgram, {
            searchStr: str,
        });
    };

    const onSearch = throttle(_onSearch, 500, {
        leading: false,
        trailing: true,
    });

    const renderListItem = (it, i) => {
        const item = it;

        let params = item.params;
        if (typeof item.params === 'string') {
            params = JSON.parse(item.params);
        }
        let classifierIcon;
        if (params && params.icons && params.icons.length) {
            params.icons.forEach(item => {
                classifierIcon = <ClassifierIcon key={item} imageId={item} />;
            });
        }

        let styles = {};
        let historyState = {};
        if (params && params.isLargeCard) {
            styles.gridColumn = 'span 2';
        }
        let navigationPath = '';
        let navigationParams;

        if (data.listType === 'institution' && item.params && item.params.sponsorScreen) {
            const { sponsorScreen } = item.params;

            const sponsor =
                navigation.current &&
                navigation.current.find(item => item.to.indexOf(sponsorScreen) > -1);

            if (sponsor && sponsor.pageId) {
                navigationParams = {
                    pageType: 'sponsor',
                    pageId: sponsor.pageId,
                };
            }
        }

        if (!navigationParams) {
            navigationParams = {
                type: 'detail',
                objectClass: item.category || data.listType,
                objectId: item.id,
            };
        }

        if (item && item.showClickBooth) {
            navigationPath = `/exhibitorbooth/${item.id}`;
            navigationParams = {};
        }

        if (item.eurekaId && isAuthenticated) {
            historyState = {
                personFallbackNavigationParams: {
                    ...navigationParams,
                },
            };
            navigationPath = '';
            navigationParams = {
                type: 'profile',
                userId: item.eurekaId,
                personLinkedId: item.id,
            };
        }

        return (
            <ListItemWithLik
                key={`ListItemWithLik_${item.id}`}
                i={i}
                item={item}
                styles={styles}
                classifierIcon={classifierIcon}
                insetDivider={insetDivider}
                data={data}
                path={navigationPath}
                params={navigationParams}
                historyState={historyState}
            />
        );
    };

    const content = () => {
        if (!data.displayItems.length && !loading) {
            if (data.listInfo) {
                return null;
            }
            if (data.myProgram === 'my_program' && (!data.searchStr || data.searchStr === '')) {
                return (
                    <EmptyState>
                        <h4>{TEXT_EMPTY_LIST_FAVORITE_TITLE}</h4>
                        <p>{TEXT_EMPTY_LIST_FAVORITE_SUBTITLE}</p>
                        <PlaceholderImage myprogram />
                    </EmptyState>
                );
            } else {
                return (
                    <EmptyState>
                        <h4>{TEXT_EMPTY_LIST_FILTER_TITLE}</h4>
                        <p>{TEXT_EMPTY_LIST_FILTER_SUBTITLE}</p>
                        <PlaceholderImage />
                    </EmptyState>
                );
            }
        } else {
            const ListsWrapperComponent = data.gridLayout ? ListsWrapper : React.Fragment;
            const Wrapper = data.gridLayout ? GridLayout : ListStyled;

            return (
                <ListsWrapperComponent>
                    {data.classifierByType ? (
                        <>
                            {data.listByClassifiers.map(it => (
                                <>
                                    <ClassifierSectionTitle>
                                        {it.classifierName}
                                    </ClassifierSectionTitle>
                                    <Wrapper
                                        className=""
                                        key={`list_${props.pageId}`}
                                        id="object-item-list"
                                    >
                                        {it.items?.map(renderListItem)}
                                    </Wrapper>
                                </>
                            ))}
                        </>
                    ) : (
                        <Wrapper id="object-item-list" className="" key={`list_${props.pageId}`}>
                            {listItems.map(renderListItem)}
                        </Wrapper>
                    )}
                </ListsWrapperComponent>
            );
        }
    };

    return (
        <AccessRestrictions pageId={props.pageId}>
            <BrandingBanner
                banner={banner}
                visibleFullSideMenu={props.visibleFullSideMenu}
                navigationType={props.navigationType}
                title={titleOnBanner}
                subtitle={subtitleOnBanner}
                setTitleOnHeader={props.setTitle}
                disableBanner={props.disableBanner}
                showInContentContainer={!!props.navigationType}
                moveContentContainerUp
            >
                {data.slideshow && data.slideshow?.length > 0 && (
                    <Box paddingTop={2}>
                        <Slideshow
                            data={data.slideshow}
                            itemDuration={3000}
                            autoplay
                            indicators
                            style={{ aspectRatio: '4', padding: 16, borderRadius: 16 }}
                        />
                    </Box>
                )}
                {data.hasSearch && (
                    <FiltersContainer
                        issticky={
                            (data.searchStr !== '' && !detailPage) ||
                            props.navigationType === 'sideMenu'
                        }
                        top={
                            data.searchStr !== '' &&
                            !detailPage &&
                            props.navigationType !== 'sideMenu'
                                ? '88px'
                                : props.navigationType === 'sideMenu' && 0
                        }
                    >
                        <FiltersActionContainer>
                            <SearchContainer>
                                <Search
                                    action={onSearch}
                                    live={true}
                                    skey={props.pageId}
                                    isExpandableSearch={true}
                                    displayLeft={true}
                                />
                            </SearchContainer>
                        </FiltersActionContainer>
                    </FiltersContainer>
                )}
                {loading && <Loader />}
                {!loading && (
                    <React.Fragment>
                        {data.listInfo && !subtitleOnBanner && (
                            <ListInformation>{data.listInfo}</ListInformation>
                        )}
                        {content()}
                        {displayMore && (
                            <MoreButtonContainer>
                                <Button
                                    onClick={loadMore}
                                    type={buttonTypes.GREY}
                                    text={LOAD_MORE_BUTTON}
                                />
                            </MoreButtonContainer>
                        )}
                    </React.Fragment>
                )}
            </BrandingBanner>
        </AccessRestrictions>
    );
};
export default ListPage;
