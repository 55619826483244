import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-right: 24px;
    }
`;

const LinkIcon = styled(FontIcon)`
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.87) !important;
`;

const LinkText = styled.button`
    font-family: 'Roboto', sans-serif;
    color: ${props => props.primary || '#00a933'};
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    border: none;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;

    &:hover {
        text-decoration: underline;
    }
`;

const InstitutionDetailLinkItem = ({ icon = 'link', onClick, primary, text, style }) => {
    return (
        <LinkWrapper style={style}>
            <LinkIcon iconClassName="material-icons-outlined">{icon}</LinkIcon>
            <LinkText onClick={onClick} primary={primary}>
                {text}
            </LinkText>
        </LinkWrapper>
    );
};

export default InstitutionDetailLinkItem;
