import { getItem } from '../../../services/api/graphQlRepository';
import sortBy from 'lodash/sortBy';
import { mapStudioNav } from '../../../components/Navigation/utils/utils';

const getMenuPage = function (pageId, pages, next) {
    getItem('pages', pageId, (err, page) => {
        if (page) {
            let data = { ...page };
            if (page.navItems && page.navItems.length > 0) {
                const ordered = sortBy(page.navItems, 'order');
                data.studioNavItems = mapStudioNav(ordered, pages);
            }
            next(null, data);
        } else {
            next(null, null);
        }
    });
};
export { getMenuPage };
