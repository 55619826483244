import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as palette from '../../../../components/General/Variables';
import AuthUserContext from '../../../../components/Session/AuthUserContext';
import { getTimeslot } from '../../services/session';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import {
    getVirtualEventPollSets,
    getVirtualEventSession,
    getVirtualEventToken,
    removeVirtualEventToken,
    updateVirtualEventToken,
} from '../../../../services/api/eureka';
import { EUREKA_TALK_HOST } from '../../../../config';

import Auth from '../../../../services/api/auth';
import { goBackFromVirtualSession } from '../../components/common/goBack';
import AnalyticsService from '../../../../features/analytics/services';
import NotificationService from '../../../Notifications/services/NotificationService';
import { notificationTypes } from '../../../Notifications/constants';
import { parseVirtualEventSession } from '../../../../utils/sortUtils';
import {
    AchievementType,
    useAchievementActions,
} from '../../../Achievements/hooks/useAchievementActions';
import useExpertAchievementTimeTracking from '../../../Achievements/hooks/useExpertAchievementTimeTracking';
import { Box, Stack } from '@mui/material';
import Chat from '../userInteraction/Chat';

const ChatSession = props => {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();

    const { match, history, location } = props;
    const { socket, timeslot } = stateCtx;
    const { timeslotId } = match.params;
    const virtualEventUserInfo = useRef(null);

    const { trackAchievement } = useAchievementActions();
    useExpertAchievementTimeTracking(timeslot);

    const sessionId = timeslotId;
    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const user = Auth.getUser();

    const refreshSession = async () => {
        const virtualEventSession = await getVirtualEventSession(timeslotId);

        if (!virtualEventSession || !virtualEventSession.VirtualEventUsers) {
            return false;
        }

        const virtualEventUserActive = virtualEventSession.VirtualEventUsers.find(
            virtualEventUser => virtualEventUser.isActive || virtualEventUser.hasVideoActive,
        );

        mutationCtx.setVirtualEventSession(virtualEventSession);

        if (virtualEventUserActive) {
            mutationCtx.setVirtualEventUser(virtualEventUserActive);
        } else {
            mutationCtx.setVirtualEventUser(null);
        }

        return true;
    };

    const updateData = data => {
        const newState = {};
        const { virtualEventSession } = data;

        if (virtualEventSession) {
            const parsedVirtualEventSession = parseVirtualEventSession(virtualEventSession);
            const virtualEventUsers = parsedVirtualEventSession.VirtualEventUsers;
            const virtualEventUserActive = virtualEventUsers.find(
                virtualEventUser => virtualEventUser.isActive || virtualEventUser.hasVideoActive,
            );

            newState.virtualEventSession = parsedVirtualEventSession;

            if (virtualEventUserActive) {
                newState.virtualEventUser = virtualEventUserActive;
            } else {
                newState.virtualEventUser = null;
            }
        }

        mutationCtx.setExtraState(newState);
    };

    const fetchPollSets = async () => {
        const virtualEventSession = await getVirtualEventSession(timeslotId);

        if (!virtualEventSession) {
            return;
        }

        try {
            const fetchedPollSets = await getVirtualEventPollSets(
                virtualEventSession.ExternalObjectId,
            );

            mutationCtx.setPollSets(fetchedPollSets);
        } catch (err) {
            console.log('PollSets fetch error', err);
        }
    };

    useEffect(() => {
        (async () => {
            const virtualEventSession = await getVirtualEventSession(timeslotId);

            if (!virtualEventSession || !virtualEventSession.id || !user || !user.id) {
                console.log(`session: ${get(virtualEventSession, 'id')}; user: ${get(user, 'id')}`);
                return;
            }

            const virtualEventTokenResponse = await getVirtualEventToken({
                sessionId: timeslotId,
                role: 'SUBSCRIBER',
            });
            const virtualEventToken = virtualEventTokenResponse.virtualEventToken;
            const appID = virtualEventTokenResponse.appID;

            virtualEventUserInfo.current = { tokenId: virtualEventToken.id, timeslotId };

            mutationCtx.setCanJoin(true);
            mutationCtx.updateConfig({
                appID,
                token: virtualEventToken.accessToken,
                channelName: timeslotId,
            });

            if (socket) {
                socket.emit('refreshAttendees', { sessionId });
            }

            trackAchievement(AchievementType.JOIN_VIRTUAL_SESSION, timeslotId);
        })();
    }, [timeslotId]);

    useEffect(() => {
        (async () => {
            if (!user) {
                return goBackFromVirtualSession(match, history, location, timeslotId);
            }

            await fetchPollSets();

            const sessionRefreshed = await refreshSession();

            if (!sessionRefreshed) {
                return;
            }

            mutationCtx.setEurekaChatUrl(EUREKA_TALK_HOST);
        })();

        const cleanup = async () => {
            window.removeEventListener('beforeunload', cleanup);

            let tokenId = null;

            if (virtualEventUserInfo && virtualEventUserInfo.current) {
                tokenId = virtualEventUserInfo.current.tokenId;
            }

            if (tokenId) {
                await updateVirtualEventToken({ userId: user.id, status: 'disconnected' });
                await removeVirtualEventToken({ tokenId });

                if (socket) {
                    socket.emit('refreshAttendees', { sessionId });
                }
            }

            mutationCtx.clearAllStream();
        };

        window.addEventListener('beforeunload', cleanup);

        // Add a 'view' sample every time a user access the virtual session page
        AnalyticsService.addSample('object', 'timeslot', timeslotId);

        return async () => {
            await cleanup();
        };
    }, []);

    useEffect(() => {
        const { socket } = stateCtx;

        if (!socket || !user) {
            return;
        }

        const refreshEverythingForSession = async () => {
            await refreshSession();
        };

        socket.on('connect', refreshEverythingForSession);
        socket.on(`updateData_${timeslotId}`, updateData);
        socket.on(`messageNotification_${user.id}`, data => {
            if (sessionId !== data.roomId) {
                NotificationService.handleNotification({
                    notificationType: notificationTypes.CHAT,
                    userId: data.senderId,
                    type: data.type,
                    roomId: data.roomId,
                });
            }
        });

        return () => {
            if (socket) {
                socket.off('connect', refreshEverythingForSession);
                socket.removeAllListeners(`updateData_${timeslotId}`);
                socket.removeAllListeners(`messageNotification_${user.id}`);
            }
        };
    }, [stateCtx.socket, timeslotId]);

    useEffect(() => {
        getTimeslot(timeslotId, async (err, timeslot) => {
            if (err || !timeslot) {
                return;
            }
            mutationCtx.setTimeslot(timeslot);
        });
    }, [timeslotId]);

    const conversation = { roomId: timeslotId };

    return (
        <Stack alignItems="center" style={{ backgroundColor: palette.COLOR_WHITE }} height="100vh">
            <Box
                width={{ xs: 'calc(100% - 32px)', sm: 520, md: 800 }}
                position="relative"
                height="100%"
                overflow="scroll"
            >
                <Chat conversation={conversation} isPresentation />
            </Box>
        </Stack>
    );
};

const ConsumerWrapper = props => {
    return (
        <AuthUserContext.Consumer>
            {authUser => <ChatSession {...props} authUser={authUser} />}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(ConsumerWrapper);
