import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${props => (props.isbeamerview ? 88 : 48)}px;
    width: 100%;
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
    border-radius: 6px 0 0 6px;
    background-color: ${props => props.color};
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    z-index: 100;
    ${props =>
        props.isbeamerview &&
        css`
            width: 42px;
            font-size: 21px;
        `}
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    width: 100%;
    height: 100%;
    background-color: ${props => props.color}15;
    color: rgba(0, 0, 0, 0.87);
    font-size: ${props => (props.isbeamerview ? 20 : 15)}px;
    margin-left: 2px;
    z-index: 100;
    border-radius: 0 6px 6px 0;
    ${props =>
        props.ishovered &&
        css`
            background-color: ${props.color};
            cursor: pointer;
            color: #fff;
        `}
`;

const VoteOption = ({
    label,
    option,
    placeholder,
    color,
    votingIsActive,
    onSubmitVote,
    isBeamerView,
    isMultipleChoice,
    selectedOptionIds,
    setSelectedOptionIds,
}) => {
    const [isOptionHovered, setIsOptionCovered] = useState(false);
    const isSelected = (selectedOptionIds || []).includes(option.id);

    const onSetIsOptionCovered = value => {
        if (votingIsActive) {
            setIsOptionCovered(value);
        }
    };

    const onVoteClick = () => {
        if (votingIsActive && isMultipleChoice) {
            onSubmitVote([option.id]);
        }
        if (votingIsActive && !isMultipleChoice) {
            if (isSelected) {
                setSelectedOptionIds(selectedOptionIds.filter(id => id !== option.id));
            } else {
                setSelectedOptionIds([...selectedOptionIds, option.id]);
            }
        }
    };

    return (
        <FlexRow
            onMouseEnter={() => {
                onSetIsOptionCovered(true);
            }}
            onMouseLeave={() => {
                onSetIsOptionCovered(false);
            }}
            onClick={onVoteClick}
            isbeamerview={isBeamerView ? 1 : 0}
        >
            <LabelWrapper
                color={color}
                notEmpty={option.text !== ''}
                isbeamerview={isBeamerView ? 1 : 0}
            >
                {label}
            </LabelWrapper>
            <TextWrapper
                placeholder={placeholder}
                color={color}
                ishovered={isSelected || isOptionHovered ? 1 : 0}
                isbeamerview={isBeamerView ? 1 : 0}
            >
                {option.text}
            </TextWrapper>
        </FlexRow>
    );
};

export default VoteOption;
