import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Typography, Box } from '@mui/material';
import Icon from '@mui/material/Icon';
import { getBlackOrWhiteCalculated } from '../../../../components/General/Colors';
import { getImageSourceAsync } from '../../../../services/api/db';
import { mapStudioNav } from '../../../../components/Navigation/utils/utils';
import { usePagesStore } from '../../../../stores/PagesStore';

const GAP = 16;
const HEIGHT = 72;

const WidgetButton = ({ button }) => {
    const [containerWidth, setContainerWidth] = React.useState(0);
    const [buttonWithNav, setButtonWithNav] = React.useState(button);

    const location = useLocation();

    const pathParts = location.pathname.split('/');
    const basePath = `/${pathParts[1]}/${pathParts[2]}`;

    const pages = usePagesStore(state => state.pages);

    React.useEffect(() => {
        const updateContainerWidth = () => {
            const navigationWidgetContainer = document.getElementById(
                'navigation-widget-container',
            );
            const width = navigationWidgetContainer?.getBoundingClientRect()?.width || 0;
            setContainerWidth(width);
        };

        updateContainerWidth();

        window.addEventListener('resize', updateContainerWidth);

        return () => {
            window.removeEventListener('resize', updateContainerWidth);
        };
    }, []);

    React.useEffect(() => {
        const loadButtonData = async () => {
            const { imageUrl, image } = button || {};
            let url = '';
            if (image) {
                url = await getImageSourceAsync(image);
            } else if (imageUrl) {
                url = imageUrl;
            }
            let to = {};
            let webUrl = '';
            const [navigationData] = mapStudioNav([button], pages, false);
            if (navigationData?.to) {
                to = `${basePath}${navigationData?.to}`;
            } else if (navigationData?.url) {
                webUrl = navigationData?.url;
            }
            setButtonWithNav({ ...button, displayImage: url, to, webUrl });
        };
        loadButtonData();
    }, [button, pages]);

    const {
        size,
        bgColor: color,
        grid: { col },
        top,
        to,
        icon,
        name,
        type,
        displayImage,
        webUrl,
    } = buttonWithNav;

    let bgColor = color;
    if (!color.startsWith('#')) {
        bgColor = `#${color}`;
    }

    const length = size !== 'lg' ? containerWidth / 2 : containerWidth;
    const height = size === 'sm' ? HEIGHT : HEIGHT * 2 + GAP;
    const width = length - (size !== 'lg' ? 0.5 : 0) * GAP;

    const commonStyle = {
        height,
        width,
        borderRadius: 8,
        overflow: 'hidden',
        top,
        left: length * col + (col ? GAP / 2 : 0),
        position: 'absolute',
        boxShadow: '0 3px 6px 0 rgba(0,0,0,0.3)',
    };

    return type === 'image' && displayImage ? (
        <Button
            component={to ? Link : undefined}
            style={{
                ...commonStyle,
            }}
            sx={{
                p: 0,
            }}
            to={to || undefined}
            onClick={() => (webUrl ? window.open(webUrl, '_blank') : null)}
        >
            <Box
                component="img"
                src={displayImage}
                alt={name}
                sx={{
                    height: '100%',
                    width,
                    maxWidth: width,
                    objectFit: 'cover',
                    borderRadius: 1,
                    overflow: 'hidden',
                }}
            />
        </Button>
    ) : (
        <Button
            component={to ? Link : undefined}
            variant="contained"
            style={{
                ...commonStyle,
                flexDirection: size === 'sm' ? 'row' : 'column',
                backgroundColor: bgColor,
                gap: GAP,
                justifyContent: size === 'sm' ? 'flex-start' : 'center',
                padding: 24,
            }}
            to={to || undefined}
            onClick={() => (webUrl ? window.open(webUrl, '_blank') : null)}
        >
            <Icon
                sx={{
                    color: getBlackOrWhiteCalculated(bgColor),
                }}
                style={{
                    fontSize: 32,
                }}
            >
                {icon.materialName}
            </Icon>
            <Typography
                sx={{ fontWeight: 500, color: getBlackOrWhiteCalculated(bgColor) }}
                style={{
                    fontFamily: 'Roboto',
                    fontSize: '17px',
                    fontWeight: 500,
                    lineHeight: 1.18,
                    textTransform: 'none',
                }}
            >
                {name}
            </Typography>
        </Button>
    );
};

const NavigationWidget = ({ widget }) => {
    const { buttons, height } = React.useMemo(() => {
        const wButtons = [...(widget?.buttons || [])];
        const sorted = wButtons.sort((a, b) => a.grid?.row - b.grid?.row);
        const currentTop = {
            0: -GAP,
            1: -GAP,
        };

        sorted.reduce((maxItem, currentItem) => {
            const {
                grid: { col, row },
                size,
            } = currentItem;

            if (size === 'lg') {
                currentTop[0] += GAP;
                currentTop[1] += GAP;
            } else {
                currentTop[col] += GAP;
            }

            currentItem.top = currentTop[col];
            const heightToAdd = size !== 'sm' ? 2 * HEIGHT + GAP : HEIGHT;
            if (size === 'lg') {
                currentTop[0] += heightToAdd;
                currentTop[1] += heightToAdd;
            } else {
                currentTop[col] += heightToAdd;
            }
            return row > maxItem.grid.row ? currentItem : maxItem;
        }, sorted[0]);

        const height = Math.max(currentTop[0], currentTop[1]);
        return { buttons: wButtons, height };
    });

    return (
        <Box id="navigation-widget-container" sx={{ position: 'relative', height: height }}>
            {buttons.map(button => (
                <WidgetButton key={button._id} button={button} />
            ))}
        </Box>
    );
};

export default NavigationWidget;
