import { parseCCLink } from '../../../helper/CCLink';

export const navigationIconsWithClass = [
    { configuratorIcon: 'calendar-outline', webappIcon: 'event' },
    { configuratorIcon: 'calendar-text-outline', webappIcon: 'event_note' },
    {
        configuratorIcon: 'forum-outline',
        webappIcon: 'forum',
    },
    { configuratorIcon: 'folder-account-outline', webappIcon: 'folder_shared' },
    { configuratorIcon: 'account-outline', webappIcon: 'person_outline' },
    {
        configuratorIcon: 'picture-in-picture-top-right-outline',
        webappIcon: 'picture_in_picture',
    },
    { configuratorIcon: 'account-box-outline', webappIcon: 'account_box' },
    { configuratorIcon: 'heart-outline', webappIcon: 'favorite_border' },
    { configuratorIcon: 'domain', webappIcon: 'domain' },
    {
        configuratorIcon: 'file-outline',
        webappIcon: 'insert_drive_file',
    },
    { configuratorIcon: 'magnify', webappIcon: 'search' },
    { configuratorIcon: 'map-marker-outline', webappIcon: 'location_on' },
    { configuratorIcon: 'dots-horizontal', webappIcon: 'more_horiz' },
    {
        configuratorIcon: 'reorder-horizontal',
        webappIcon: 'reorder',
    },
    { configuratorIcon: 'file-document-box-multiple-outline', webappIcon: 'article' },
    { configuratorIcon: 'alert-decagram-outline', webappIcon: 'new_releases' },
    {
        configuratorIcon: 'information-outline',
        webappIcon: 'info_outline',
    },
    { configuratorIcon: 'tag-outline', webappIcon: 'local_offer' },
    { configuratorIcon: 'file-document-outline', webappIcon: 'description' },
    { configuratorIcon: 'file-multiple-outline', webappIcon: 'file_copy' },
    {
        configuratorIcon: 'book-outline',
        webappIcon: 'book',
    },
    { configuratorIcon: 'play-box-outline', webappIcon: 'slideshow' },
    { configuratorIcon: 'clipboard-text-outline', webappIcon: 'assignment' },
    { configuratorIcon: 'email-outline', webappIcon: 'email' },
    {
        configuratorIcon: 'television-classic',
        webappIcon: 'tv',
    },
    { configuratorIcon: 'cloud-download-outline', webappIcon: 'cloud_download' },
    { configuratorIcon: 'home-outline', webappIcon: 'home' },
    {
        configuratorIcon: 'check-box-outline',
        webappIcon: 'check_box_outline',
    },
    { configuratorIcon: 'format-list-bulleted', webappIcon: 'list' },
    { configuratorIcon: 'message-outline', webappIcon: 'chat_bubble_outline' },
    { configuratorIcon: 'dns-outline', webappIcon: 'dns' },
    {
        configuratorIcon: 'checkbox-multiple-blank-outline',
        webappIcon: 'filter_none',
    },
    { configuratorIcon: 'earth', webappIcon: 'public' },
    { configuratorIcon: 'map-outline', webappIcon: 'map' },
    { configuratorIcon: 'cellphone', webappIcon: 'smartphone' },
    {
        configuratorIcon: 'account-group-outline',
        webappIcon: 'groups',
    },
    { configuratorIcon: 'camera-outline', webappIcon: 'camera_alt' },
    { configuratorIcon: 'star-outline', webappIcon: 'star_border' },
    { configuratorIcon: 'view-agenda-outline', webappIcon: 'view_agenda' },
    { configuratorIcon: 'volume-high', webappIcon: 'volume_up' },
    { configuratorIcon: 'microphone', webappIcon: 'mic' },
];

const removeActions = [
    'signout',
    'signin',
    'myconversations',
    'mynotes',
    'myprogramme',
    'myfavorites',
    'attendee_list',
    'attendees',
    'eventwall',
    'social_feed',
];

const removeRestrictedNavItemsInKioskMode = (nav, pages) => {
    const toRemove = [...removeActions];
    pages.forEach(item => {
        if (nav && nav.length && nav.find(navElem => navElem.screen === item.name)) {
            if (
                !!item.accessRestrictions ||
                !!item.isPasswordProtected ||
                !!item.withAccessRestriction
            ) {
                toRemove.push(item.name);
            }
        }
    });

    return nav.filter(item => {
        console.log(item.screen);
        console.log(toRemove);
        console.log(toRemove.indexOf(item.screen));
        return toRemove.indexOf(item.screen) === -1;
    });
};

export const getWebappIconUsingClass = classIcon => {
    const selectedIcon = navigationIconsWithClass.find(it => it.configuratorIcon === classIcon);
    return selectedIcon?.webappIcon;
};

const socialFeedNames = ['eventwall', 'social_feed'];
const attendessListNames = ['attendee_list', 'attendees'];

export const mapMenuPageItemsParams = (items, kiosk, pages) => {
    if (!items) {
        return [];
    }

    const nav = items.map(item => {
        const { commonTitle, title, name, page, imageId, url } = item;

        if (url) {
            return {
                title: commonTitle || title,
                imageId: imageId,
                url,
            };
        }

        if (socialFeedNames.includes(name)) {
            return {
                title: commonTitle || title,
                imageId: imageId,
                to: `/eventwall`,
                type: 'eventwall',
                screen: 'eventwall',
            };
        }

        if (attendessListNames.includes(name)) {
            return {
                title: commonTitle || title,
                imageId: imageId,
                to: `/attendees`,
                type: 'attendees',
                screen: 'attendees',
            };
        }

        let foundPage;
        if (name) {
            foundPage = pages.find(page => page.name === name);
        }
        if (!foundPage && page) {
            foundPage = pages.find(page => page.id === page);
        }

        if (foundPage && foundPage.kind === 'object') {
            return {
                title: commonTitle || title,
                imageId: imageId,
                to: `/object/${foundPage?.params?.objectId || ''}`,
                type: 'object',
            };
        }

        return {
            title: commonTitle || title,
            imageId: imageId,
            to: `/${foundPage?.name || ''}`,
            type: foundPage?.kind || '',
            pageId: foundPage.id,
            screen: foundPage?.name,
        };
    });

    if (kiosk) {
        return removeRestrictedNavItemsInKioskMode(nav, pages);
    }

    return nav;
};

export const mapMobileNavigationMenu = (mobileNavigationSource, kiosk, pages) => {
    if (!mobileNavigationSource) {
        return [];
    }

    let nav = [];

    nav = mobileNavigationSource.map(item => {
        const { screen, label, webappIcon, class: iconClass } = item;

        let subitems = [];
        const foundPage = pages.find(page => page.name === screen);

        if (
            foundPage &&
            foundPage.kind === 'menu' &&
            foundPage.params?.items &&
            foundPage.params?.items.length
        ) {
            subitems = mapMenuPageItemsParams(foundPage.params?.items, kiosk, pages);
        }

        if (foundPage && foundPage.kind === 'object') {
            return {
                title: label,
                webappIcon: webappIcon || getWebappIconUsingClass(iconClass),
                to: `/object/${foundPage?.params?.objectId || ''}`,
                type: 'object',
            };
        }

        return {
            title: label,
            webappIcon: webappIcon || getWebappIconUsingClass(iconClass),
            to: `/${foundPage?.name || ''}`,
            type: foundPage?.kind || '',
            pageId: foundPage.id,
            screen: foundPage?.name,
            subItems: subitems,
        };
    });

    if (kiosk) {
        return removeRestrictedNavItemsInKioskMode(nav, pages);
    }

    return nav;
};

export const mapNavigationMenu = (navItems, pages, kiosk) => {
    let nav = navItems.map(item => {
        if (item.to) {
            return item;
        } else if (item?.screen === 'object') {
            let foundPage;
            if (item?.pageId) {
                foundPage = pages.find(page => page.id === item.pageId);
            }

            return {
                ...item,
                to: `/object/${foundPage?.params?.objectId || ''}`,
                type: 'object',
            };
        } else {
            return {
                ...item,
                to: item.to || `/${item.screen || ''}`,
            };
        }
    });

    if (kiosk) {
        return removeRestrictedNavItemsInKioskMode(nav, pages);
    }

    return nav;
};

export const mapStudioNav = (navItems, pages, kiosk) => {
    let nav = navItems.map(item => {
        const parsedPageData = parseCCLink(item.url || '');

        if (!parsedPageData) {
            return {
                title: item.name,
                icon: item.icon,
                url: item.url,
                isStudio: true,
            };
        }

        const { screen, pageId, objectId } = parsedPageData;

        if (objectId) {
            return {
                title: item.name,
                icon: item.icon,
                to: `/object/${objectId || ''}`,
                type: 'object',
                objectId,
                isStudio: true,
            };
        }

        let foundPage;
        let subitems = [];
        if (pageId) {
            foundPage = pages.find(page => page.id === pageId);
        } else if (screen) {
            foundPage = pages.find(page => page.name === screen);
        }

        if (
            foundPage &&
            foundPage.kind === 'menu' &&
            foundPage.navItems &&
            foundPage.navItems.length
        ) {
            subitems = mapStudioNav(foundPage.navItems, pages, kiosk);
        }

        let kind = foundPage?.kind || '';

        if (foundPage?.kind === 'networking') {
            kind = foundPage?.name;
        }

        return {
            title: item.name,
            icon: item.icon,
            to: `/${foundPage?.name || ''}`,
            type: kind,
            pageId: foundPage?.id,
            screen: foundPage?.name,
            subItems: subitems,
            isStudio: true,
        };
    });

    if (kiosk) {
        return removeRestrictedNavItemsInKioskMode(nav, pages);
    }

    return nav;
};
