import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

import styled from 'styled-components';
import * as palette from './Variables';
import useResize from '../../scenes/VirtualSession/hooks/useResize';
import { getImageSourceAsync } from '../../services/api/db';
import { useMediaQuery } from '@mui/material';

const Content = styled.div`
    margin-top: ${props => (props.margintop ? props.margintop : 56)}px;
    overflow: hidden;
    ${props =>
        !props.notList &&
        `
            max-width: 1200px;
        `}
    width: calc(100% - 32px);
    ${props =>
        props.moveContentContainerUp &&
        `
        margin-top: ${
            props.hasTitle && props.hasSubtitle ? '-156px' : props.hasTitle ? '-122px' : '-64px'
        };
    `}
    border-radius: 8px;
    height: ${props =>
        props.viewtype && props.isScrolled
            ? 'calc(100vh - 80px)'
            : props.viewtype
            ? 'calc(100% - ' +
              ((props.imageheight || 0) + (props.margintop || 0) + 2 - props.scrollOffset) +
              'px)'
            : undefined};
    position: relative;
    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        overflow: scroll;
    }
    ${props =>
        !props.notList &&
        `
    @media only screen and (min-width: 1360px) {
        overflow: scroll;
        left: 50%;
        transform: translateX(-50%);
        }
    `}
`;

const BackgroundImageWrapper = styled.div`
    position: relative;
    transition: opacity 1s;
    z-index: -1;
    width: 100%;
    aspect-ratio: 5 / 1;
    @media only screen and(max-width: ${palette.MAX_PHONE}) {
        display: none;
    }
`;

const BackgroundImagePlaceholder = styled.div`
    background-color: #e5e5e5;
`;

const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
`;

const TitleSection = styled.div`
    z-index: 1;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-left: 16px;

    div:first-of-type {
        font-family: Cabin, sans-serif;
        font-size: 56px;
        font-weight: bold;
        line-height: 1;
    }

    div:nth-of-type(2) {
        font-family: Roboto, sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6;
        margin-top: 8px;
    }
`;

const ContentContainer = styled.div`
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.05),
        0 0 1px 0 rgba(0, 0, 0, 0.15);
    height: 100%;
    margin: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
`;

const BrandingBanner = ({
    notList,
    children,
    banner,
    viewType,
    navigationType,
    activeTab,
    title,
    subtitle,
    setTitleOnHeader,
    disableBanner,
    withDynamicMarginTop = false,
    showInContentContainer = false,
    moveContentContainerUp = false,
}) => {
    const [scrollableElement, setScrollableElement] = useState(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(0);
    const bannerWrapperRef = useResize({ ratio: 5 / 1 });

    const isPhone = useMediaQuery('(max-width:480px)');
    const isDesktop = useMediaQuery('(min-width:1360px)');
    const backgroundImageRef = useRef(null);
    const wrapperRef = useRef(null);
    const [imageHeight, setImageHeight] = useState(null);
    const [backgroundUrl, setBackgroundUrl] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);
    const marginTop = isPhone ? 0 : (imageHeight || 280) / 5;
    const containerTopOffset = withDynamicMarginTop && imageHeight - scrollOffset;

    useEffect(() => {
        if (wrapperRef.current) {
            const h = wrapperRef.current.getBoundingClientRect().width;
            if (h) {
                setImageHeight(wrapperRef.current.getBoundingClientRect().width / 5);
            }
        }
    }, [wrapperRef.current]);

    useEffect(() => {
        if (backgroundImageRef.current) {
            const h = backgroundImageRef?.current?.offsetHeight;
            if (h) {
                setImageHeight(backgroundImageRef?.current?.offsetHeight);
            }
        }
    }, [backgroundImageRef.current]);

    useEffect(() => {
        const loadImage = async () => {
            let img = '';
            try {
                img = await getImageSourceAsync(banner);
            } catch (e) {
                img = '';
            }

            if (img) {
                setBackgroundUrl(img);
            }
        };
        if (banner) {
            loadImage();
        }
    }, [banner]);

    useEffect(() => {
        if (viewType) {
            setTimeout(
                () => {
                    setScrollableElement(
                        document.getElementById(
                            `timetable-scrollable-container-${viewType.toLowerCase()}`,
                        ),
                    );
                },

                2000,
            );
        } else {
            setScrollableElement(document.getElementById('page-content'));
        }
    }, [viewType, activeTab]);

    useEffect(() => {
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollableElement, viewType]);

    const handleScroll = throttle(() => {
        const imageElement = document.getElementById('header-image-container');
        const container = document.getElementById('header-content-container');

        if (!scrollableElement || !imageElement) {
            return;
        }
        const newScroll = scrollableElement.scrollTop;
        setScrollOffset(oldScroll => (oldScroll + newScroll) / 2);
        const imageHeight = imageElement.offsetHeight;

        if (withDynamicMarginTop) {
            imageElement.style.transform = `translateY(-${newScroll}px)`; // Parallax effect
        }

        if (newScroll > imageHeight) {
            if (setTitleOnHeader) {
                setTitleOnHeader();
            }
            if (viewType) {
                imageElement.style.display = 'none';
            }

            if (withDynamicMarginTop) {
                container.style.transform =
                    isDesktop && !notList ? `translate(-50%, 0px)` : `translateY(0px)`; // Parallax effect
            }
            setIsScrolled(true);
        } else {
            imageElement.style.display = 'block';
            imageElement.style.opacity = newScroll === 0 ? '1' : 1 - newScroll / imageHeight;
            if (withDynamicMarginTop && imageHeight - newScroll > marginTop + 16) {
                container.style.transform =
                    isDesktop && !notList
                        ? `translate(-50%, -${newScroll}px)`
                        : `translateY(-${newScroll}px)`; // Parallax effect
            }

            setIsScrolled(false);

            if (setTitleOnHeader && title) {
                setTitleOnHeader(title);
            }
        }
    }, 10);

    const renderTitle = useCallback(() => {
        return title ? (
            <TitleSection>
                {title && <div>{title}</div>}
                {subtitle && <div>{subtitle}</div>}
            </TitleSection>
        ) : null;
    }, [title, subtitle]);

    const contentProps = useMemo(() => {
        return {
            id: 'header-content-container',
            margintop: isPhone ? 16 : !isScrolled && withDynamicMarginTop ? -marginTop : 16,
            imageheight: imageHeight,
            notList,
            navigationType,
            viewtype: viewType,
            isScrolled,
            moveContentContainerUp: moveContentContainerUp && !disableBanner && banner,
            hasTitle: title ? true : false,
            hasSubtitle: subtitle ? true : false,
            scrollOffset:
                containerTopOffset > marginTop + 16
                    ? scrollOffset
                    : imageHeight
                    ? imageHeight - marginTop - 16
                    : 0,
        };
    }, [
        isPhone,
        isScrolled,
        withDynamicMarginTop,
        marginTop,
        imageHeight,
        notList,
        navigationType,
        viewType,
        scrollOffset,
        containerTopOffset,
        moveContentContainerUp,
        title,
        subtitle,
        disableBanner,
        banner,
    ]);

    const renderContent = useCallback(() => {
        return (
            <Content {...contentProps}>
                {renderTitle()}
                <ContentContainer style={{ paddingTop: title ? 16 : undefined }}>
                    {children}
                </ContentContainer>
            </Content>
        );
    }, [contentProps, renderTitle, children]);

    if (!banner || disableBanner) {
        if (showInContentContainer) {
            return renderContent();
        }
        return children;
    }

    return (
        <div
            ref={wrapperRef}
            style={{
                position: 'relative',
                height: 'calc(100vh - 64px)',
            }}
        >
            {!isPhone && (
                <BackgroundImageWrapper id={'header-image-container'} ref={bannerWrapperRef[1]}>
                    {!imageLoaded && (
                        <BackgroundImagePlaceholder style={{ height: `${imageHeight}px` }} />
                    )}
                    {backgroundUrl.length > 0 && (
                        <BackgroundImage
                            src={backgroundUrl}
                            alt="Banner"
                            innerRef={backgroundImageRef}
                            onLoad={() => {
                                setImageLoaded(true);
                            }}
                            style={{ height: !imageLoaded ? '0px' : undefined }}
                        />
                    )}
                </BackgroundImageWrapper>
            )}
            {renderContent()}
        </div>
    );
};

export default BrandingBanner;
