import React from 'react';
import NavigationWidget from './widgets/NavigationWidget';
import SponsorBannerWidget from './widgets/SponsorBannerWidget';
import NewsFeedWidget from './widgets/NewsFeedWidget';
import VideoWidget from './widgets/VideoWidget';
import HappeningNowWidget from './widgets/HappeiningNowWidget';
import WidgetTitle from './widgets/WidgetTitle';
import Stack from '@mui/material/Stack';

const WidgetDistributor = ({ widget }) => {
    let WidgetComponent = null;
    switch (widget.kind) {
        case 'navigation':
            WidgetComponent = NavigationWidget;
            break;
        case 'sponsor_banner':
            WidgetComponent = SponsorBannerWidget;
            break;
        case 'news_feed':
            WidgetComponent = NewsFeedWidget;
            break;
        case 'video':
            WidgetComponent = VideoWidget;
            break;
        case 'happening_now':
            WidgetComponent = HappeningNowWidget;
            break;
        default:
            return null;
    }
    return (
        <Stack spacing={2}>
            <WidgetTitle widget={widget} />
            <WidgetComponent widget={widget} />
        </Stack>
    );
};

export default WidgetDistributor;
