import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { getBlackOrWhiteCalculated } from '../../../components/General/Colors';
import { useHomeBannerUrl } from '../../../stores/PagesStore';

const HomePageBanner = ({ homePage }) => {
    const homeBannerUrl = useHomeBannerUrl();

    return (
        <Stack position="relative" width="100%">
            {homeBannerUrl && (
                <img
                    src={homeBannerUrl}
                    alt="home-banner"
                    style={{ width: '100%', aspectRatio: 4 }}
                />
            )}
            <Box
                key={`banner-${homePage?.useGradient?.toString()}-${homePage?.homeBgColor}`}
                display="flex"
                position="absolute"
                width="100%"
                height="50%"
                bottom={0}
                padding={2}
                alignItems="flex-end"
                style={{
                    backgroundImage: homePage?.useGradient
                        ? `linear-gradient(to bottom, rgba(0, 0, 0, 0), ${
                              homePage?.homeBgColor || '#f0f0f0'
                          })`
                        : 'transparent',
                }}
            />
            {homePage?.title && homePage.showTitleOnBanner && (
                <Box position="absolute" bottom={16} left={16}>
                    <Typography
                        variant="h1"
                        style={{
                            fontSize: 68,
                            lineHeight: 1,
                            color: getBlackOrWhiteCalculated(homePage.homeBgColor),
                            fontFamily: 'Cabin',
                            fontWeight: 700,
                        }}
                    >
                        {homePage?.title}
                    </Typography>
                </Box>
            )}
        </Stack>
    );
};

export default HomePageBanner;
