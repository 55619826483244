import React, { useCallback, useEffect, useState } from 'react';
import findIndex from 'lodash/findIndex';
import { withRouter } from 'react-router-dom';
import NavigationLink from '../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import Loader from '../../components/General/Loader';
import ThemeContext from '../../components/Theme/ThemeContext';
import Floorplan from './components/floorplan';
import FloorplanList from './components/list';
import { getFloorplan, getPlaceById } from './services/floorplan';
import InfoCard from './components/BoothInfoCard';
import PlaceScheduleList from './components/PlaceScheduleList';
import {
    TabElement,
    TabsContainerStyled,
    TabsWrapper,
    ToolbarContainer,
} from '../Programme/style/style';

const StyledLink = styled(NavigationLink)`
    font-weight: 500;
`;

const FloorplanPage = props => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sections, setSections] = useState([]);
    const [currentActivePlace, setCurrentActivePlace] = useState(null);
    const [currentActivePlaceIsBooth, setCurrentActivePlaceIsBooth] = useState(false);
    const { pageId, match } = props;

    useEffect(() => {
        getFloorplan(props.pageId, async (err, result) => {
            if (result && result.page && result.floorplans && result.floorplans.length) {
                setItems(result.floorplans);
                const sections =
                    result.page.sections && result.page.sections.length
                        ? result.page.sections
                        : result.page.params.sections;
                setSections(sections);
                setLoading(false);

                await loadPlace(match.params.place);

                if (!match.params.floorplan) {
                    if (
                        result.floorplans.length &&
                        result.floorplans[result.page.params.initialItem || 0]
                    ) {
                        return props.history.push(
                            `${props.location.pathname}/${
                                result.floorplans[result.page.params.initialItem || 0].id
                            }${match.params.place ? `/${match.params.place}` : ''}`,
                        );
                    }
                }
            }
        });
    }, [pageId, match.params.floorplan, match.params.place]);

    useEffect(() => {
        loadPlace(match.params.place);
    }, [match.params.place]);

    const loadPlace = async matchPlace => {
        const place = await getPlaceById(matchPlace);

        if (!place) {
            setCurrentActivePlace(null);
            setCurrentActivePlaceIsBooth(false);
            return;
        }

        const type = sections.find(section => section.typeId === place.type);
        const isBooth = type && type.title.toLowerCase().includes('booth');

        setCurrentActivePlace(place);
        setCurrentActivePlaceIsBooth(isBooth);
    };

    const openDrawerRight = e => {
        setShowDrawer(true);
    };

    const onDrawerClose = e => {
        setShowDrawer(false);
    };

    const onActiveFloorplan = useCallback(
        id => {
            const index = findIndex(items, item => {
                return id === item.id;
            });
            setSelectedTab(index);
        },
        [items],
    );

    return (
        <React.Fragment>
            {loading && <Loader />}
            {!loading && (
                <React.Fragment>
                    <ThemeContext.Consumer>
                        {({ theme }) => (
                            <ToolbarContainer>
                                <TabsContainerStyled
                                    defaultTabIndex={0}
                                    activeTabIndex={selectedTab}
                                    panelClassName="md-grid"
                                    colored
                                    onTabChange={() => {}}
                                >
                                    <TabsWrapper
                                        background={theme.primary}
                                        tabId="floorplan"
                                        mobile
                                        alignToKeyline={false}
                                    >
                                        {items.map((tab, i) => (
                                            <TabElement
                                                key={i}
                                                component={StyledLink}
                                                pathReplaceParams={{
                                                    floorplan: tab.id,
                                                    place: null,
                                                }}
                                                label={tab.name}
                                                primary={theme.primary}
                                                active={selectedTab === i}
                                                onClick={() => this.setState({ selectedTab: i })}
                                            />
                                        ))}
                                    </TabsWrapper>
                                </TabsContainerStyled>
                            </ToolbarContainer>
                        )}
                    </ThemeContext.Consumer>
                    {match.params.floorplan && (
                        <>
                            <Floorplan
                                {...props}
                                searchButtonAction={openDrawerRight}
                                onActiveFloorplan={onActiveFloorplan}
                            />
                            {showDrawer && (
                                <FloorplanList
                                    sections={sections}
                                    visible={showDrawer}
                                    onClose={onDrawerClose}
                                />
                            )}
                            {currentActivePlace && currentActivePlaceIsBooth && (
                                <InfoCard item={currentActivePlace} />
                            )}
                            {currentActivePlace && !currentActivePlaceIsBooth && (
                                <PlaceScheduleList place={currentActivePlace} />
                            )}
                        </>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default withRouter(FloorplanPage);
