import React, { useContext, useEffect, useRef, useState } from 'react';
import sortBy from 'lodash/sortBy';
import NavigationLink from '../../../components/Navigation/NavigationLink';
import throttle from 'lodash/throttle';
import styled from 'styled-components';
import TimeslotListItem from '../../../components/ObjectListItem/Timeslot';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { fetchUpcomingSessions } from '../api';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/General/Loader';
import NoUpcomingSessionsPlaceholder from '../components/NoUpcomingSessionsPlaceholder';
import { TimezoneContext } from '../../Timezone/context';
const Scrollable = styled.section.attrs({
    className: 'scrollable-div',
})`
    height: 100%;
    overflow: auto;
`;

const UpcomingSessionsDetailPage = ({ updateTitle, pageId, placeholderWidth }) => {
    const [sessions, setSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [noSessionsMessage, setNoSessionsMessage] = useState('');
    const navBar = useRef();
    const { getHappeningNowNextQuery } = useContext(TimezoneContext);

    let scroll = 0;
    let scrollDirection = 'UP';
    const _handleScroll = throttle(() => {
        if (!navBar.current) {
            return;
        }
        let newScroll = document.getElementById('scrollable').scrollTop;

        if (scroll > newScroll && scrollDirection !== 'UP' && newScroll > 48) {
            navBar.current.className = 'fixed-navbar';
            scrollDirection = 'UP';
        } else if (scroll <= newScroll && scrollDirection !== 'DOWN' && newScroll > 48) {
            navBar.current.className = 'auto-navbar';
            scrollDirection = 'DOWN';
        }

        scroll = newScroll;
    }, 100);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const appState = await getLocalAppStateAsync();
            const { sessions: fetchedSessions, message, title } = await fetchUpcomingSessions(
                appState.eventId,
                pageId,
                null,
                getHappeningNowNextQuery,
            );

            if (title && updateTitle && typeof updateTitle === 'function') {
                updateTitle(title);
            }

            const sessions = sortBy(fetchedSessions, ['start', 'name']);
            setSessions(sessions);
            setIsLoading(false);
            setNoSessionsMessage(message);
        })();
    }, [pageId]);

    return (
        <>
            <Scrollable id="scrollable" onScroll={_handleScroll}>
                {isLoading && <Loader />}

                {!isLoading &&
                    sessions.map((it, index) => {
                        const item = it;

                        return (
                            <NavigationLink
                                key={`detail-page-upcoming-session-${index}`}
                                params={{
                                    type: 'detail',
                                    objectClass: 'timeslot',
                                    objectId: item.id,
                                }}
                            >
                                <TimeslotListItem timeslot={item} />
                            </NavigationLink>
                        );
                    })}
                {!isLoading && sessions.length === 0 && (
                    <NoUpcomingSessionsPlaceholder
                        text={noSessionsMessage}
                        width={placeholderWidth}
                    />
                )}
            </Scrollable>
        </>
    );
};

export default withRouter(UpcomingSessionsDetailPage);
