import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

import { getString } from '../../services/api/store';
import { getFormatDate, getFormatTime } from '../../services/api/data';
import { TimezoneContext } from '../../scenes/Timezone/context';
import { Flex } from '../../scenes/common/Flex';

const HeaderTime = styled.div`
    padding: 16px 16px 16px 16px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: start;
`;

const DateTimeContainer = styled(Flex)`
    color: rgba(28, 28, 30, 1);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    i {
        color: rgba(28, 28, 30, 1) !important;
        font-size: 16px; !important;
        margin-right: 10px;
    }
`;

function TimeslotDetailHeader(props) {
    const { isAppointment } = props;
    const { getUtcToSelectedTimezone, getUnixToSelectedTimezone } = useContext(TimezoneContext);

    const dateSettings = getString('datetime');
    const locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';

    let startTime;
    let endTime;

    if (isAppointment) {
        startTime = getUnixToSelectedTimezone(props.start);
        endTime = getUnixToSelectedTimezone(props.end);
    } else {
        startTime = getUtcToSelectedTimezone(props.start);
        endTime = getUtcToSelectedTimezone(props.end);
    }

    const timeFormat = getFormatTime();
    const dateFormat = getFormatDate();

    const dayToDisplay = startTime.locale(locale).format(dateFormat);
    const timeToDisplay = `${startTime.format(timeFormat)} - ${endTime.format(timeFormat)}`;

    return (
        <HeaderTime>
            <Flex justifyContent={'space-between'} width={'100%'}>
                <DateTimeContainer>
                    <FontIcon>event</FontIcon>
                    <div>{dayToDisplay || ''}</div>
                </DateTimeContainer>
                <DateTimeContainer>
                    <FontIcon>access_time</FontIcon>
                    <div>{timeToDisplay}</div>
                </DateTimeContainer>
            </Flex>
        </HeaderTime>
    );
}

export default TimeslotDetailHeader;
