import axios from 'axios';
import moment from 'moment';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import {
    EVENT_NOT_CONFIGURED_MESSAGE,
    EVENT_NOT_FOUND_MESSAGE,
    USER_NOT_ATTENDING_PERMISSIONS,
} from '../../App';
import { USE_CONFIGURATOR, USE_LOCAL } from '../../config';

import { addLocalItemAsync, getLocalAppState } from './db';
import * as eureka from './eureka';
import { clearLocalStorage } from './graphQlCache';
import { executeQuery } from './graphQlRepository';
import AnalyticsService from '../../features/analytics/services';
import Auth from '../../services/api/auth';

async function getConfigurations(eventId) {
    const data = {};
    const result = await executeQuery('getConfigurationsWithEvent', {
        event: eventId,
    });
    if (!(result && result.length)) {
        throw new Error('Did not find configurations');
    }
    each(result, function (configuration) {
        if (configuration.name === 'style.json') {
            let styles = configuration.value;

            if (styles[eventId] && styles[eventId].style) {
                let style = { name: styles[eventId].style.name };
                if (styles[eventId].style.all && styles[eventId].style.all.color) {
                    style.colors = styles[eventId].style.all.color;
                }
                data.style = style;
            }
        }
        if (configuration.name === 'arguments.json') {
            let args = configuration.value;
            if (args[eventId] && args[eventId].argument) {
                let argument = {};
                if (args[eventId].argument.all) {
                    argument = args[eventId].argument.all;
                }

                argument.name = args[eventId].argument.name;
                data.settings = argument;
            }
        }
        if (configuration.name === 'strings.json') {
            let json = configuration.value;
            if (json[eventId] && json[eventId].string) {
                let strings = {};
                if (json[eventId].string.all) {
                    strings = json[eventId].string.all;
                }

                strings.name = json[eventId].string.name;
                data.strings = strings;
            }
        }
    });
    return data;
}

const getSocietyInfo = async function (config) {
    const url = config.configuratorUrl + '/api/events/societyId';
    const response = await axios.get(url);
    const result = response && response.data ? response.data : null;

    if (!config.societyId) {
        config.societyId = result && result.societyId ? result.societyId : 'legacy';
    }

    if (result && result.loginSettings && result.loginSettings.externalProfileSync) {
        config.externalProfileSync = result.loginSettings.externalProfileSync;
    }

    if (
        result &&
        result.loginSettings &&
        result.loginSettings.type &&
        result.loginSettings.type === 'custom'
    ) {
        config.externalEurekaLogin = result.loginSettings.externalLoginSettings.externalEurekaLogin;
        config.signInButtonTitle = result.loginSettings.externalLoginSettings.signInButtonTitle;
        config.externalLoginOptions =
            result.loginSettings.externalLoginSettings.externalLoginOptions;
    } else {
        config.externalEurekaLogin = null;
        config.signInButtonTitle = null;
        config.externalLoginOptions = null;
    }

    if (result) {
        config.oneSignalAppId = result.oneSignalAppId;
        config.oneSignalSafariWebId = result.oneSignalSafariWebId;
        config.branchIoDomain = result.branchIoDomain;
        config.signInBackgroundImageUrl = result.signInBackgroundImageUrl;
        config.societyLogoUrl = result.loginSettings?.logoUrl;
        config.termsUrl = result.termsUrl;
    }

    return config;
};

const getEventData = async function (config, isSociety) {
    const url = config.configuratorUrl + '/api/events/latest/grid';
    const response = await axios.get(url);
    if (response && response.data) {
        if (isSociety) {
            //Here we can add a parsing function to keep only the data we need
            config.events = response.data;
            const settings = response.data.find(d => d.kind === 'settings' && d.id === config.id);
            config.settings = settings;
        } else {
            const eventData = response.data.find(d => d.type === 'event' && d.id === config.id);
            config.groupCanSignOnly = eventData.groupCanSignOnly;
            config.groupCanAccessOnly = eventData.groupCanAccessOnly;
            config.eurekaOnly = eventData.eurekaOnly;
            config.attendingOnly = eventData.attendingOnly;
            config.hiddenPassword = eventData.hiddenPassword;
            config.timezoneSelection = eventData.timezoneSelection;
            config.start = eventData.start;
            config.end = eventData.end;
            config.eventLogo = eventData.icon;
            config.timezone = eventData.timezone;
            config.metaFusionClientId = eventData.metaFusionClientId;
            config.metaFusionEnabled = eventData.metaFusionEnabled;
            if (eventData.webIcon) {
                config.pageHeader = eventData.webIcon;
            }
        }
    }
    return config;
};

const getAnalyticsInstallationId = async config => {
    return AnalyticsService.setup(config.configuratorUrl, config.id);
};

async function loadEventState(eventName, config) {
    const domain = eventName;
    const result = await executeQuery('getAppStatesWithDomain', { domain }, true);
    if (isArray(result)) {
        [config] = result;
    }

    const [home] = await executeQuery(
        'getPagesWithEventAndKind',
        {
            event: config.eventId,
            kind: 'home',
        },
        true,
        1,
    );

    // Set up with studio home properties

    console.log(home);

    const { webapp, kiosk, navItems } = home || {};

    config.mobileNavItems = navItems || [];

    if (webapp?.navItems?.length) {
        config.navigation = webapp.navItems;
        config.hasStudioPages = true;
        config.webapp = webapp;
    } else if (webapp?.useTopNav && navItems && navItems.length) {
        config.navigation = navItems;
        config.hasStudioPages = true;
        config.webapp = webapp;
    }

    if (kiosk) {
        config.kiosk = kiosk;
        if (kiosk.password && kiosk.password.length) {
            config.isKioskPasswordProtected = true;
            config.kioskPassword = kiosk.password;
        }
    }

    if (!(config && config.eventId && config.navigation)) {
        throw new Error(EVENT_NOT_FOUND_MESSAGE);
    } else if (config.navigation.length === 0) {
        throw new Error(EVENT_NOT_CONFIGURED_MESSAGE);
    } else {
        const storedReleaseNum = localStorage.getItem(`releaseNum_${config.eventId}`);
        if (
            !parseInt(storedReleaseNum) ||
            parseInt(storedReleaseNum) !== parseInt(config.releaseNum)
        ) {
            // Invalidate local cache
            await clearLocalStorage();
        }

        const storedInstallationId = localStorage.getItem(`installationId_${config.eventId}`);
        if (storedInstallationId && storedInstallationId !== 'undefined') {
            config.installationId = storedInstallationId;
        }

        await getSocietyInfo(config);
        await getEventData(config);

        // if (USE_LOCAL && USE_CONFIGURATOR) {
        //     config.configuratorUrl = 'http://virtual-test.localhost:3001';
        // }

        await addLocalItemAsync('appState', 'config', config);

        localStorage.setItem(`releaseNum_${config.eventId}`, config.releaseNum);

        const { attendingOnly, groupCanAccessOnly } = config;

        config.installationId = await getAnalyticsInstallationId(config);
        if (config.installationId) {
            localStorage.setItem(`installationId_${config.eventId}`, config.installationId);
        }

        if (Auth.isUserAuthenticated()) {
            if (attendingOnly || groupCanAccessOnly) {
                const user = Auth.getUser();
                let isAttending = await Auth.isUserAttendingByEmail(config.eventId, user.email);
                if (groupCanAccessOnly) {
                    isAttending = false;
                    const currentUser = Auth.getUser();
                    const result = await Auth.checkIfEmailExistsAsync(currentUser.email);
                    const { userGroups } = result;
                    if (userGroups && userGroups.length) {
                        isAttending = userGroups.some(item => item.id === groupCanAccessOnly);
                    }
                }

                if (isAttending) {
                    return initAuthenticatedUser(config);
                } else {
                    throw new Error(USER_NOT_ATTENDING_PERMISSIONS);
                }
            } else {
                return initAuthenticatedUser(config);
            }
        }
    }
}

const initAuthenticatedUser = async config => {
    return new Promise((resolve, reject) => {
        eureka.sync(err => {
            if (err) {
                reject(err);
            }
            const data = {
                events: [
                    {
                        id: config.eventId,
                        installationDate: moment().format(),
                    },
                ],
            };

            eureka.updateUserEventInstallations(data, () => {
                resolve(config);
            });
        });
    });
};

async function loadSocietyState(config) {
    const parsedDomain = window.location.hostname.split('.')[0];
    const societyDomain = parsedDomain.replace('-cui', '');
    const { EUREKA_URL, data: societyData } = await eureka.getSocietyInfo(societyDomain);

    if (!(societyData && societyData.reference && societyData.domain === societyDomain)) {
        throw new Error('Society not found');
    }

    const { reference, url, title, domain } = societyData;

    config.societyId = reference;
    config.appName = title;
    config.configuratorUrl = url;
    config.societyDomain = domain;
    config.eurekaUrl = EUREKA_URL;
    config.eurekaOnly = true;

    await getSocietyInfo(config);

    // next line breaks the app after some time has passed since login
    // config.installationId = await AnalyticsService.setup(config.configuratorUrl, config.societyId);

    await getEventData(config, true);

    if (
        process.env.REACT_APP_USE_LOCAL === 'true' &&
        process.env.REACT_APP_USE_CONFIGURATOR === 'true'
    ) {
        // config.configuratorUrl = 'http://virtual-test.localhost:3001';
    }

    await addLocalItemAsync('appState', 'config', config);

    return config;
}

function updateApp(eventName, next) {
    let config = {};
    if (eventName) {
        loadEventState(eventName, config)
            .then(configuration => {
                next(null, configuration);
            })
            .catch(err => {
                const error = err.message || err;

                next(error);
            });
    } else {
        loadSocietyState(config)
            .then(configuration => {
                next(null, configuration);
            })
            .catch(err => {
                const error = err.message || err;
                next(error);
            });
    }
}

export { updateApp, getLocalAppState, getConfigurations };
