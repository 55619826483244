import React from 'react';
import Slideshow from '../../../ExhibitorPage/containers/Slideshow';

const SponsorBannerWidget = ({ widget }) => {
    return (
        <Slideshow
            data={widget.slideshow}
            noIndicators={true}
            autoplay
            itemDuration={widget.itemDuration}
        />
    );
};

export default SponsorBannerWidget;
