import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import StreamPlayer from './StreamPlayer';
import { FIFTY_MILL, FORTY_MILL, TEN_MILL, THIRTY_MILL, TWENTY_MILL } from '../../constants/values';
import useResize from '../../hooks/useResize';
import entities from '../../constants/entities';
import Store from '../../../../services/api/store';
import { getImageSource } from '../../../../services/api/db';

const { virtualEventSessionEntity } = entities;

const SlidesScreenContainer = styled.div`
    background-color: #fff;
    border: ${props => (props.small ? 'solid 1px #ccc' : '')};
    height: 100%;
    position: relative;
    text-align: center;
    border-radius: 8px;
`;

const Img = styled.img`
    max-height: 100%;
    max-width: 100%;
    display: block;
    border-radius: 8px;
`;

const ImageWrapper = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: center;
    max-height: calc(100vh - 100px);
    height: 100%;
`;

const ScreenWrapper = styled.div`
    height: 100%;

    .stream-player {
        height: 100%;
        width: 100%;

        [id*='player'] {
            background: transparent !important;
        }

        video {
            background: #fff;
            border-radius: 8px;
            border: 1px solid #ccc;
            object-fit: contain !important;
            /* This used to work for the parent element of button divs */
            /* But it does not work with newer browsers, the below doesn't hide the play button parent div */
            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
            }

            /* Old shadow dom for play button */

            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
            }

            /* New shadow dom for play button */

            /* This one works! */

            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
            }
        }
    }
`;

const PresentationOrScreen = () => {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const {
        mainMedia,
        streams,
        virtualEventSession,
        virtualEventUser,
        localClient,
        mainMediaSwitchedByUser,
        pollSets,
    } = stateCtx;
    const { activeVideoId } = virtualEventSession;
    const mainIsSlides = mainMedia === 'slides';
    const small = mainIsSlides ? 0 : 1;
    const polls = get(pollSets, 'Polls', []);
    const activePoll = polls.find(poll => poll.active);
    const isRoundTable =
        get(virtualEventSession, 'roomType') === virtualEventSessionEntity.roomType.roundTable;
    const playAudioOnlyFromCamera = get(
        virtualEventUser,
        'recordingInfo.playAudioOnlyFromCamera',
        false,
    );
    const virtualSessionsSlideHolder = Store.argument && Store.argument.virtualSessionsSlideHolder;
    const [eventSliderPlaceholder, setEventSliderPlaceHolder] = useState(
        '/images/slide-placeholder.png',
    );
    let muted = playAudioOnlyFromCamera;
    const videoUrls = get(virtualEventSession, 'videoUrls') || [];
    const [wrapperRef] = useResize();
    const activeVideo = useMemo(() => videoUrls.find(videoObj => videoObj.id === activeVideoId), [
        videoUrls,
    ]);
    const activeVideoHasShareOnly = activeVideo && activeVideo.shareUrl && !activeVideo.videoUrl;
    const placeHolderUrl =
        get(virtualEventSession, 'presentationPlaceholderUrl') ||
        eventSliderPlaceholder ||
        '/images/slide-placeholder.png';
    const isPrerecordingActive = get(virtualEventUser, 'hasVideoActive');
    const preRecordingHasSlidesAndShare =
        (get(virtualEventUser, 'recordingInfo.list') || []).length === 2;

    const streamToUse = useMemo(() => {
        const hasVideoActive = get(virtualEventUser, 'hasVideoActive');
        let streamId = get(virtualEventUser, 'UserId', 0);

        if (hasVideoActive) {
            streamId += THIRTY_MILL;
        } else if (activeVideoId) {
            streamId = virtualEventSession.id;
            streamId += FIFTY_MILL;
        } else {
            streamId += TEN_MILL;
        }

        return (streams || []).find(
            stream =>
                virtualEventSession.status === 'broadcasting' &&
                stream &&
                (virtualEventUser || activeVideo) &&
                stream.streamId === streamId,
        );
    }, [get(virtualEventSession, 'status'), virtualEventUser, streams, activeVideo]);

    const isCurrentStreamRTMP = useMemo(() => {
        if (
            virtualEventUser &&
            virtualEventUser.rtmpInfo &&
            virtualEventSession.status === 'broadcasting'
        ) {
            return !!streams.find(st => st.streamId === virtualEventUser.rtmpInfo.uid);
        }
    }, [get(virtualEventSession, 'status'), virtualEventUser, streams, activeVideoId]);

    const streamId = get(streamToUse, 'streamId');
    const activeStreamIsSlidesOrShare = streamId >= TEN_MILL && streamId <= TWENTY_MILL;

    // make sure the screensharing is unmuted
    if (activeStreamIsSlidesOrShare) {
        muted = false;
    }

    if (isRoundTable) {
        muted = false;
    }

    const notBroadcasting = get(virtualEventSession, 'status') !== 'broadcasting';
    const showPlaceHolder = notBroadcasting || !streamToUse;

    useEffect(() => {
        (async () => {
            if (virtualSessionsSlideHolder) {
                await getImageSource(virtualSessionsSlideHolder, (err, img) => {
                    setEventSliderPlaceHolder(img);
                });
            }
        })();
    }, []);

    useEffect(() => {
        if (streamToUse) {
            if (localClient) {
                localClient.setStreamFallbackOption(streamToUse, 0);
            }
        }
    }, [streamToUse]);

    useEffect(() => {
        // We only do these switches if the user has not changed views manually
        if (!mainMediaSwitchedByUser) {
            if (notBroadcasting && !mainIsSlides) {
                mutationCtx.switchMain();
            }

            // when active speaker has slides, slides should be in the main container
            else if (activeStreamIsSlidesOrShare && !mainIsSlides) {
                mutationCtx.switchMain();
                // when active speaker does not have slides, and placeholder is shown, switch to video
            } else if (
                !activeStreamIsSlidesOrShare &&
                mainIsSlides &&
                showPlaceHolder &&
                activeVideo
            ) {
                mutationCtx.switchMain();
                // when active speaker does not have slides, speaker video / panel should be in the main container
            } else if (
                !activeStreamIsSlidesOrShare &&
                mainIsSlides &&
                !activeVideo &&
                !isPrerecordingActive &&
                !notBroadcasting &&
                streamToUse
            ) {
                mutationCtx.switchMain();
                // when uploaded video has only slides or share, these should be in the main container
            } else if (!mainIsSlides && activeVideoHasShareOnly && activeVideo) {
                mutationCtx.switchMain();
                // when uploaded video has both video and slides, these should be in the main container
            } else if (!mainIsSlides && activeVideo && !activeVideoHasShareOnly) {
                mutationCtx.switchMain();
                // when pre-recording is active with both slides & video, slides should be in the main container
            } else if (!mainIsSlides && isPrerecordingActive && preRecordingHasSlidesAndShare) {
                mutationCtx.switchMain();
                // when there is only RTMP active
            } else if (
                mainIsSlides &&
                !streamToUse &&
                !activeVideo &&
                !activePoll &&
                !isPrerecordingActive &&
                isCurrentStreamRTMP
            ) {
                mutationCtx.switchMain();
            }
        }
    }, [
        mainIsSlides,
        activeStreamIsSlidesOrShare,
        activeVideoHasShareOnly,
        activeVideo,
        streamToUse,
        pollSets,
        activePoll,
        notBroadcasting,
        isCurrentStreamRTMP,
    ]);

    return (
        <SlidesScreenContainer innerRef={wrapperRef} small={small}>
            {showPlaceHolder ? (
                <ImageWrapper data-qa="virtual-session-placeholder-stream">
                    <Img alt="" src={placeHolderUrl} />
                </ImageWrapper>
            ) : (
                <ScreenWrapper>
                    {streamToUse && (
                        <StreamPlayer
                            key={`stream-player-${streamToUse.getId()}-screen`}
                            local={false}
                            stream={streamToUse}
                            isVideoOn={true}
                            muted={muted}
                            isPlaying={streamToUse.isPlaying()}
                            uid={streamToUse.getId()}
                            domId={`stream-player-${streamToUse.getId()}-screen`}
                        />
                    )}
                </ScreenWrapper>
            )}
        </SlidesScreenContainer>
    );
};

export default PresentationOrScreen;
